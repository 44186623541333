@import "./access-control.page.scss";
@import "./landing.page.scss";

@media screen and (prefers-reduced-motion: reduce) {
  * {
    transition: 0s !important;
    &:before, &:after {
      transition: 0s !important;
    }
  }
}
