// ======================================
//  New Variables
// ======================================
// Colors Main
$green-main:  #00d6ae;
$yellow-main: #f0e345;
$red-main:    #e8500c;
$blue-main:   #10df72;
$grey-main:   #f0f6f7;

$base-foreground: rgb(0, 30, 30);
$smaller-font: 0.75rem;


// Colors
$black:                 #000;
$dark-grey:          #262626;
$dark-grey-mid:      #2e2e2e;
$grey:               #5E5E5E;
$grey1:              #959da5;
$grey2:              #868686;
$grey3:              #aaaaaa;
$grey4:              #727989;
$grey5:              #54595d;
$grey6:              #b7bbc3;
$grey7:              #AFAFAF;
$grey8:              #888888;
$mid-grey:           #7C7C7C;
$before-grey:        #CDCDCD;
$light-grey:         #E2E2E2;
$clear-grey:         #F7F7F7;
$obfuscated-grey:    #BFBFBF;
$new-grey:           #707070;
$white:              #FFFFFF;
$white1:             #f4f4f4;
$white2:             #eeeeee;
$red:                #aa0f16;
$red1:               #ffafaf;
$red2:               #ff0000;
$redDD:              #dd4b39;
$gold:               #CCB16E;
$golden1:            #d28c00;
$golden2:            #fcedd0;
$green:              #a3bd31;
$green1:             #dee8ca;
$green2:             #8dd200;
$light-green: #4dd165;
$blue:               #0080FF;
$blue1:              #91c8ff;
$blue2:              #3e5792;
$blue3:              #1c86f9;
$blue4:              #19599f;
$blue28:             #283e4a;
$blue3a:             #3a5998;
$blue5b:             #5baaf4;
$light-blue:         #ddeef9;
$blue-azure-darker:  #6985A0;
$blue-azure-lighter: #82A7C8;
$black-transparent: rgba(0, 0, 0, 0.25);
$before-grey-transparent:  rgba(205, 205, 205, 0.25); // #cdcdcd
$dark-grey-transparent:    rgba(38, 38, 38, 0.7);
$dark-grey-transparent-40: rgba(38, 38, 38, 0.4);
$dark-grey-transparent-90: rgba(38, 38, 38, 0.9);
$white-transparent: rgba(255, 255, 255, 0.8);

// Font Family
$font-family: 'Roboto', sans-serif;;

// Linear-Gradients
$lg-blue:   linear-gradient($blue1, $blue);
$lg-golden: linear-gradient($golden2, $golden1);
$lg-green:  linear-gradient($green1, $green2);
$lg-red:    linear-gradient($red1, $red2);
$lg-grey:   linear-gradient($white, $before-grey);
$lg-black:  linear-gradient($grey, $dark-grey);

// font sizes
$fs-xxxxs: 0.65rem;  // 8px;
$fs-xxxs:  0.75rem;  // 10px;
$fs-xxs:   0.88rem;  // 12px;
$fs-xs:    1rem;     // 14px;
$fs-xsm:   1.125rem; // 16px;
$fs-sm:    1.25rem;  // 18px;
$font-24:  1.5rem;   // 20px;
$fs-md:    1.75rem;  // 24px;
$fs-lg:    2.375rem; // 34px;
$fs-xl:    4.375rem; // 66px;

// Letter Spacing
$ls-short: .02em;

// Borders
$border-primary: 1px solid $before-grey;
$border-secondary: 1px solid $grey;
$border-terciary: 1px solid $before-grey-transparent;

// Border-radius
$border-radius-raw: 4;
$border-radius: #{$border-radius-raw}px;
$border-radius-raw-2x: 8;
$border-radius-2x: #{$border-radius-raw-2x}px;
$border-radius-primary: 10px;
$border-radius-secondary: 10px;


// Shadows
$shadow-primary: 0px 2px 2px $before-grey;
$shadow-secondary: 0px 1px 1px 0px $before-grey;
$shadow-terciary: 0px 0px 2px $dark-grey;
$shadow-fourth: 0px 0px 2px $black;
$shadow-fifth: 0px 0px 15px $black;
$shadow-sixth: 0px 0px 2px $before-grey-transparent;
$shadow-7: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$shadow-8: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
$shadow-9: 0 0 20px 0 rgba(0, 0, 0, 0.3);
$shadow-10: 0 2px 8px -1px rgb(0 0 0 / 30%);

// Opacity
$opacity-primary: 0.9;
$opacity-secondary: 0.4;

// Paddings
$pad-zero: 0px;
$pad-sm: 10px;
$pad-md: 20px;
$pad-lg: 40px;

// Margins MOBILE
$mg-zero: 0px;
$mg-sm: 10px;
$mg-md: 20px;
$mg-lg: 40px;
$margin-primary: 0 0 20px 0;

// Margins DESKTOP
$mg-zero: 0px;
$mg-sm: 10px;
$mg-md: 20px;
$mg-lg: 40px;
$margin-primary: 0 0 20px 0;

// Shapes
$shape-primary: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

// Layout
$max-width: 1080px;
$gutter: 10px;
$main-header-height: 60px;

// Buttons
$btn-height-sm: 30px;
$btn-height-md: 45px;
$btn-width-sm: 160px;
$btn-width-md: 200px;

// Breakpoints
$breakpoints: (
  'xs': 599px,
  'sm': 600px,
  'sm+1': 601px,
  'sm2': 720px,
  'md': 960px,
  'lg': $max-width,
  'xl': 1240px,
);

// zIndexes
$z-index: (
  'dropdown'                : 1300,
  'modal'                   : 200,
  'header'                  : 150,
  'navigation'              : 100,
  'overlay'                 : 61,
  'triangle'                : 60,
  'navigation-rainbow'      : 50,
  'share-type'              : 41,
  'footer'                  : 90,
  'share'                   : 40,
  'low': 1,
  'group-chat-left-sidebar' : 1,
  'search'   : 1,
);



$kb-theme: #30D6AE;
$kb-theme-dark: #01d7a3;


// !DEPRECATED: legacy code, don't use it
// ======================================
//  Variables
// ======================================
// ======================================
//  Old-Legacy
// ======================================
$nav-bar-blue: #009fe3;
$color-black:  #161718;
$color-xblack: #212225;
$color-gray86: #868686;
$color-dark :  #232426;
$color-gray:   #4d4e51;
$color-gray-title: #58595d;

$kb-theme: #30D6AE;
$kb-theme-dark: #01d7a3;

// Media queries
$break-xsmall: 320px;
$break-small: 562px;
$break-large: 991px;
$break-medium: 768px;
$break-xlarge: 1600px;
// Z-Index
$zIndexFooter: 10;
$zIndexDefault: 10;
$zIndexLayer2: 20;
$zIndexLayer3: 30;
$zIndexLayer4: 40;
$zIndexLayer5: 50;
$zIndexLayer6: 60;
$zIndexLayer7: 70;
$zIndexLayer8: 80;
$zIndexPopup: 99;
// Layout
$max-width-lg: 1024px;
$white-transparency: rgba(255,255,255,0.5);
$navbar-height: 30px;

// ======================================
//  END-Old-Legacy
// ======================================


$generic-table-green: #4dd165;
