$out-range-color: rgba(var(--foreground-secondary-text-rgb), 60%);
$in-range-color: var(--accent-default);

.noUi-handle {
  border-radius: 50%;
  cursor: move;
  border-color: var(--primary-default-contrast);
  background-color: var(--primary-default);
  box-shadow: none;

  &:after, &:before {
    display: none;
  }
}

.noUi-horizontal {
  height: 6px;

  .noUi-handle {
    width: 18px;
    height: 18px;
    right: -9px;
    top: -7px;
  }
}

.noUi-tooltip {
  border: none;
  border-radius: 0;
  background: transparent;
  color: var(--primary-default-contrast);
  font-size: $smaller-font;
  font-weight: 500;
  margin-bottom: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    background: var(--primary-default);
    z-index: -1;
    border-radius: 100%;
    border-bottom-right-radius: 0;
    transform: rotate(45deg);
    margin-left: -15px;
    margin-top: -15px;
  }
}

.noUi-connect:nth-child(1) {
  background-color: $out-range-color;
}

.noUi-connect:nth-child(2) {
  background-color: $in-range-color;
}

.noUi-connect:nth-child(3) {
  background-color: $out-range-color;
}

.noUi-marker-large {
  height: 8px;
}

.noUi-value {
  margin-top: 4px;
  font-size: $smaller-font;
  font-weight: bold;
}