.c-notification-overlay-wrapper {
  transform: translateX(150%);
  transition: none;

  &.enable-transition {
    transition: transform 0.2s ease-in-out;
  }
  // &.disabled-transition {
  //   transition: none;
  // }

  // &.hidden {
  //   // @extend .disabled-transition;
  //   transform: translateX(150%);
  // }
}
