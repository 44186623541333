@use '@angular/material' as mat;
[tapFeedback] {

  .effect-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.circular {
      transform: scale(1.5);
      border-radius: 50%;
      height: 89%;
    }

    &.circular-img {
      top: -50%;
      left: unset;
      transform: translate(0, 50%) scale(1.15);
      border-radius: 50%;
    }
  }

  span.tapEffect {
    display: block;
    border-radius:50%;
    width: 50px;
    height: 50px;
    position: absolute;
    animation: grow-and-hide-4 2s;
  }
}

$opacities: 10 20 30 40 50 60 70 80 90 100;

@each $opacity in $opacities {
  @keyframes grow-and-hide-#{$opacity} {
    from {
      transform: scale(1);
      opacity: $opacity * 0.01;
    }
    to {
      transform: scale(100);
      opacity: 0;
    }
  }
}

@keyframes grow-and-hide {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(100);
    opacity: 0;
  }
}

/**
  Loading directive
*/
@keyframes loading-directive-button-spinner {
  to {transform: rotate(360deg);}
}

@mixin loadingDirectiveSpin {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: rgba(0, 0, 0, 0.87);
  animation: loading-directive-button-spinner .8s linear infinite;
}

.loading-directive-element {
  &.loading-directive-active {
    &:after {
      @include loadingDirectiveSpin();

    }

    &.loading-directive-left {
      &:after {
        left: 4px;
      }
    }

    &.loading-directive-center {
      &:after {
        left: 50%;
      }
    }

    &.loading-directive-right {
      &:after {
        right: 4px;
      }
    }
  }

  &.mat-primary {
    &.loading-directive-active {
      &:after {
        border: 2px solid var(--primary-default-contrast);
        border-top-color: var(--primary-500);
      }
    }
  }

  &.mat-accent {
    &.loading-directive-active {
      &:after {
        border: 2px solid var(--accent-default-contrast);
        border-top-color: var(--accent-500);
      }
    }
  }

  &.mat-warn {
    &.loading-directive-active {
      &:after {
        border: 2px solid var(--warn-default-contrast);
        border-top-color: var(--warn-500);
      }
    }
  }
}

button.loading-directive-element {
  &.loading-directive-active {
    pointer-events: none;

    .mat-button-wrapper {
      opacity: 0.4;
    }
  }
}

mat-form-field.loading-directive-element {
  &.loading-directive-active {
    &:after {
      @include loadingDirectiveSpin();
      right: 2px;
      width: 18px;
      height: 18px;
      margin-top: -14px;
      margin-left: -10px;
    }
  }
}

.serializable-directive-item {
  position: relative;
  & > .serializable-directive-help-button {
    display: none;
    border-radius: 50%;
    background: black;
    color: white;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    border: 2px solid white;
    z-index: 10;

    & > .mat-icon {
      @include mat-icon-size(14px);
    }
  }

  &.serializable-directive-item-enabled {
    &:hover {
      & > .serializable-directive-help-button {
        display: flex;
      }
    }
  }
}
