
.macros-group {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  .group-container {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 5px;

    .group-title {
      color: color-mix(in srgb, var(--primary-300), var(--info-900) 80%);
      font-size: 14px;
    }
  }

  // app-single-macro {
  //   min-height: 35px;
  // }
}
