.webkit-scrollbar { 
  width: 8px;
  height: 18px;
}

%webkit-scrollbar-track {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
   
%webkit-scrollbar-thumb {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: rgba(99, 100, 104, 1);
  box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

%webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

%webkit-scrollbar-corner {
  background-color: transparent;
}
