@use '@angular/material' as mat;
@import "lib";

app-group-about-tab,
app-group-members-tab,
app-group-settings-tab,
app-group-feedbacks-tab {
  width: 100%;
}

mat-sidenav {
  .divergent-menu-item {
    display: flex;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    width: 100%;
    align-items: center;
    padding: 0 16px;
    height: 46px;
    font-size: 12px;
    line-height: 100%;
    font-weight: 500;
    color: var(--accent-100-contrast);//#2f426d;
    background: var(--accent-100);
    text-transform: uppercase;

    mat-icon {
      padding: 4px;
      margin-right: 6px;
      box-sizing: content-box;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .dashboard-menu-list {
    .menu-item {
      .mat-ripple {
        display: none;
      }
    }
  }
}

.global-variable {
  &.quill-variable {
    height: initial;
    min-height: initial;
    line-height: 100%;
    padding: 4px 6px 4px 10px;
    margin: 0 2px 0 0;
    font-size: inherit;
    font-weight: inherit;
    font-size: 85%;
    user-select: all;

    .material-icons {
      margin: 0 2px 0 -6px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 74%;
      width: 14px;
      height: 14px;
    }

    > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .legacy-tooltip {
      position: fixed;
      bottom: initial;
      left: initial;
      margin-top: -50px;
      transform: translateX(-20%);
    }
  }
}

.quill-cm-variable {
  display: inline-block;
}

@keyframes quill_var_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
  background: rgba(var(--foreground-text-rgb), 5%);
}

.ql-toolbar button {
  .ql-fill {
    fill: mat.get-color-from-palette($colmeia-foreground) !important;
    color: mat.get-color-from-palette($colmeia-foreground) !important;
  }
  .ql-stroke {
    stroke: mat.get-color-from-palette($colmeia-foreground) !important;
  }
}

button.ql-active,
.ql-toolbar button:hover {
  .ql-fill {
    fill: var(--accent-default) !important;
    color: var(--accent-default) !important;
  }
  .ql-stroke {
    stroke: var(--accent-default) !important;
  }
}

.reset-emoji-ap {
  background-image: unset !important;
  background-repeat:unset !important;
  background-size:unset !important;
  box-sizing:unset !important;
  display: unset !important;
  font-size:unset !important;
  height: unset !important;
  line-height:unset !important;
  margin-top:unset !important;
  overflow: unset !important;
  text-indent:unset !important;
  width: unset !important;
}

.bem.ap {
  @extend .reset-emoji-ap;
  font-size: 1.2rem !important;
}

.ql-emojiblot {
  .ap {
    @extend .reset-emoji-ap;
  }
}

.ql-editor {
  font-size: 1rem;

  .quill-cm-variable {
    position: relative;
    top: -1px;
    animation: quill_var_in 0.2s ease-in 0s 1;
  }
}

.ql-mention-list-container {
  @include mat.elevation(3);
  border: 0;
  border-radius: 14px;

  .ql-mention-list {
    padding: 8px;
  }

  .ql-mention-list-item {
    line-height: 30px;
    padding: 0 2px;
    border-left: 2px solid transparent;
    border-radius: 22px;
    transition: 0.2s;

    .quill-cm-variable {
      transition: 0.2s;
      
      > .quill-variable {
        cursor: pointer;
      }
    }

    &.selected {
      background-color: transparent;
      border-color: rgba(var(--foreground-text-rgb), 20%);

      > .quill-cm-variable {
        transform: translateX(4px);
      }
      
    }
  }
  
}
