@use "@angular/material" as mat;

.app-alert-base {
  display: flex;
  min-height: 54px;
  border-radius: $border-radius;
  width: 100%;
  padding: 12px 20px;
  align-items: center;

  div {
    word-break: break-word;

    > .alert-header {
      display: flex;
      align-items: center;

      > mat-icon {
        margin-right: 8px;
        flex-shrink: 0;
      }

      > .alert-title {
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        font-size: 0.86rem;
      }
    }

    > .alert-content {
      padding: 8px 4px 0;
    }
  }

  > button {
    margin-left: auto;
    flex-shrink: 0;
    margin-right: -6px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 8px 0;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  a {
    color: inherit;
    text-transform: underline;
    filter: brightness(2);
  }
}

.alert-hide-icon {
  div > .alert-header {
    > mat-icon {
      display: none;
    }
  }
}

.alert-dense {
  min-height: 38px;
  padding: 6px 8px;

  > button {
    $height: 28px;
    height: $height;
    line-height: $height;
    margin-right: -8px;
  }

  div > .alert-content {
    padding: 4px;
  }
}

.alert-faded-background {
  background-color: rgba(255, 255, 255, 0.5) !important;

  mat-icon {
    color: var(--alert-900);
  }
}

@mixin build-colors(
  $soft-color,
  $soft-background,
  $darker-color,
  $darker-background,
  $stroke-background,
  $stroked-color,
  $stroked-border-color: rgba($stroked-color, 50%),
  $light-icon-content: $soft-color
) {
  color: $soft-color;
  background: $soft-background;

  .mat-button {
    color: $soft-color;
  }

  &-light,
  &.alert-light {
    .alert-header {
      mat-icon {
        color: $light-icon-content;
      }
    }

    color: var(--foreground-text);
    background: var(--foreground-background);
  }

  &-darker,
  &.alert-darker {
    color: $darker-color;
    background: $darker-background;

    .mat-button {
      color: $darker-color;
    }

    &.alert-stroked {
      color: $darker-color;
      background: $darker-background;
      box-shadow: inset 0 0 0 2px $darker-color;

      .mat-button {
        color: $darker-color;
      }
    }
  }

  &-stroked,
  &.alert-stroked {
    background: $stroke-background;
    color: $stroked-color;
    box-shadow: inset 0 0 0 1px $stroked-border-color;

    .mat-button {
      color: $stroked-color;
    }
  }
}

.app-alert-info {
  $soft-color: mat.get-color-from-palette($info, "100-contrast");
  $soft-background: var(--info-100);
  $darker-color: var(--info-300);
  $darker-background: mat.get-color-from-palette($info, "300-contrast");
  $stroked-background: var(--info-50);
  $stroked-color: mat.get-color-from-palette($info, "50-contrast");
  $stroked-border-color: rgba($stroked-color, 50%);
  $light-icon-content: var(--info-900);

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color,
    $stroked-border-color,
    $light-icon-content
  );
}

.app-alert-success {
  $soft-color: mat.get-color-from-palette($success, "100-contrast");
  $soft-background: var(--success-100);
  $darker-color: var(--success-900);
  $darker-background: mat.get-color-from-palette($success, "900-contrast");
  $stroked-background: var(--success-50);
  $stroked-color: mat.get-color-from-palette($success, "50-contrast");

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color
  );
}

.app-alert-warning {
  $soft-color: mat.get-color-from-palette($alert, "100-contrast");
  $soft-background: var(--alert-100);
  $darker-color: var(--alert-900);
  $darker-background: mat.get-color-from-palette($alert, "900-contrast");
  $stroked-background: var(--alert-50);
  $stroked-color: mat.get-color-from-palette($alert, "50-contrast");
  $stroked-border-color: rgba($stroked-color, 50%);
  $light-icon-content: var(--alert-900);

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color,
    $stroked-border-color,
    $light-icon-content
  );
}

.app-alert-error {
  $soft-color: rgb(165, 0, 0);
  $soft-background: #ffdddd;
  $darker-color: #ff6969;
  $darker-background: #420000; // Answer to the Ultimate Question of Life, the Universe, and Everything.
  $stroked-background: #ffdddd;
  $stroked-color: rgb(153, 0, 0);

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color
  );
}

.app-alert-primary {
  $soft-color: var(--primary-default-contrast);
  $soft-background: var(--primary-default);
  $darker-color: mat.get-color-from-palette($primary, "A-700-contrast");
  $darker-background: mat.get-color-from-palette($primary, "A-700");
  $stroked-background: var(--primary-default-contrast);
  $stroked-color: var(--primary-default);

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color
  );
}

.app-alert-accent {
  $soft-color: var(--accent-default-contrast);
  $soft-background: var(--accent-default);
  $darker-color: mat.get-color-from-palette($accent, "A-700-contrast");
  $darker-background: mat.get-color-from-palette($accent, "A-700");
  $stroked-background: var(--accent-default-contrast);
  $stroked-color: var(--accent-default);

  @include build-colors(
    $soft-color,
    $soft-background,
    $darker-color,
    $darker-background,
    $stroked-background,
    $stroked-color
  );
}

.snack-app-alert {
  border-radius: var(--border-radius) !important;
  overflow: hidden;
  background: transparent;

  .mdc-snackbar__surface,
  .mat-mdc-snack-bar-label {
    padding: 0;
  }

}
