.multimedia-viewer-dialog-panel {
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  min-height: 100vh;
  background: rgba(0,0,0,0.8);

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      color: white;
      border-radius: 0;
      background: transparent;
    }

    cm-dialog {
      display: block;
      width: 100%; height: 100%;

      hr.vline {
        display: none;
      }

      > .wrapper {
        width: 100%;
        height: 100%;

        > section {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          > app-multimedia-player {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;

            app-multimedia-instance {
              border-radius: 10px;

              .open-document-button {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
