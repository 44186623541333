@use "@angular/material" as mat;
@import "lib";

.active1x1-modal-container {
  border-radius: 4px;

  .mat-mdc-dialog-container {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5 {
  &.with-icon {
    display: flex;
    align-items: center;

    .mat-icon {
      margin-right: 8px;
    }
  }
}

.mat-mdc-button-base {
  &.dense {
    height: 28px;
    line-height: 28px;
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
  }
}

.mat-mdc-icon-button {
  &.dense {
    --mdc-icon-button-state-layer-size: 28px;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

.center-button-content {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button-w-icon {
  letter-spacing: normal !important;

  @extend .center-button-content;

  .mat-button-wrapper {
    .material-icons {
      @include mat-icon-size(18px);
      flex-shrink: 0;
    }
  }

  &.left-align {
    .mat-button-wrapper {
      justify-content: flex-start;

      .ellipsed-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.mat-form-field.no-bottom-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    bottom: 0;
  }
}

.nice-scroll {
  @include nice-scroll();

  &.light {
    @include nice-scroll(
      $color: rgb(255 255 255 / 15%),
      $color-hover: rgb(255 255 255 / 30%)
    );
  }

  &.nice-square {
    @include nice-scroll($border-radius: 0);
  }

  &.scroll-accent {
    @include nice-scroll(
      $color: mat.get-color-from-palette($accent, 800),
      $color-hover: mat.get-color-from-palette($accent, 500)
    );
  }
}

.mat-radio-button {
  &.wrap-label {
    .mat-radio-label-content {
      white-space: initial;
    }
  }
}

.mat-checkbox {
  &.wrap-label {
    .mat-checkbox-label {
      white-space: initial;
    }

    .mat-checkbox-layout {
      .mat-checkbox-label {
        line-height: mat.line-height($colmeia-app-typography, body-1);
      }
    }
  }
}

.mat-slide-toggle {
  &.wrap-label {
    .mat-slide-toggle-content {
      white-space: initial;
      line-height: mat.line-height($colmeia-app-typography, body-1);
    }
  }
}

.info-rich-button {
  display: flex;
  padding: 12px 0;
  text-align: left;
  width: 100%;
  margin-bottom: 4px;
  border-radius: 4px;
  border: 1px solid rgba(var(--foreground-divider-rgb), 10%);
  transition: 0.2s ease-in-out;

  .icon-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 20px;
    margin-left: 16px;

    mat-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      line-height: 20px;
    }
  }

  .icon-container + .text-container {
    width: calc(100% - 32px);
  }

  .text-container {
    width: 100%;
    padding: 0 12px;

    & > .title {
      margin: 0;
      font-size: 1.3rem;
      font-weight: 400;
    }

    & > .desc {
      margin-top: 6px;
      color: var(--foreground-secondary-text);
      margin: 0;
      font-size: 12px;
    }
  }

  &.active {
    background: var(--primary-default);
    color: var(--primary-default-contrast);

    .text-container {
      & > .desc {
        color: rgba(var(--primary-default-contrast-rgb), 80%);
      }
    }
  }
}

.tooltip-contrast {
  color: #fff;
  background: rgb(black, 90%);
}

.tooltip-wrapline {
  white-space: pre-line !important;
}

.tooltip-contrast-bold {
  @extend .tooltip-contrast;
  font-weight: bold;
}

.tooltip-contrast-wrapline {
  @extend .tooltip-contrast;
  @extend .tooltip-wrapline;
}

.tooltip-contrast-large {
  @extend .tooltip-contrast;
  padding: 6px 12px !important;
  line-height: 26px;
}

.tooltip-contrast-large-wrapline {
  @extend .tooltip-contrast-large;
  @extend .tooltip-wrapline;
}

input.hidden-spin-number {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}

.aligned-actions {
  display: flex;
  justify-content: flex-end;

  & > button:not(:last-child) {
    margin-right: 6px;
  }
}

.legacy-tooltip-parent {
  &:hover > .legacy-tooltip {
    visibility: visible;
    z-index: 1;
    opacity: 1;
  }

  &.legacy-tooltip-before {
    & > .legacy-tooltip {
      transform: translateX(-100%) translateY(-50%);
      left: -8px;
      top: 50%;
      bottom: unset;
    }
  }
}

.legacy-tooltip {
  @extend .tooltip-contrast;
  position: absolute;
  bottom: 100%;
  margin-bottom: 4px;
  padding: 4px 6px;
  border-radius: $border-radius;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s;
  user-select: none !important;
}

.global-variable {
  display: inline-block;
  position: relative;
  font-size: 12px;
  padding: 2px 6px;
  margin: 1px 0;
  min-height: auto;
  height: auto;
  font-weight: inherit;
  border-radius: 100px;

  &.is-safe {
    background: mat.get-color-from-palette($primary, A100, 30%);
    color: var(--primary-A100-contrast);
    box-shadow: inset 0 0 0 1px mat.get-color-from-palette($primary, A700, 30%);

    .material-icons.type {
      background: var(--accent-700);
      color: var(--accent-700-contrast);
    }
  }

  .material-icons {
    @include mat-icon-size(14px);
    font-size: 10px;
    border-radius: 50%;

    &:not(.safe-icon) {
      background: var(--primary-100);
      color: var(--primary-100-contrast);
      margin-right: 4px;
    }

    &.safe-icon {
      @include mat-icon-size(14px);
      margin-right: -2px;
      margin-left: 4px;
    }

    &.type {
      @include mat-icon-size(16px);
      font-size: 12px;
      background: var(--primary-300);
      color: var(--primary-300-contrast);
      margin: -4px 4px -4px -2px;
    }
  }

  &:hover {
    .legacy-tooltip {
      visibility: visible;
      z-index: 1;
      opacity: 1;
    }
  }
}

.variables-container {
  font-family: inherit;
  margin: 0;
  white-space: pre-line;

  .variable {
    @extend .global-variable;
    display: inline-flex;
    align-items: center;

    &.is-safe {
      background: mat.get-color-from-palette($primary, A100, 30%);
      color: var(--primary-A100-contrast);
      box-shadow: inset 0 0 0 1px mat.get-color-from-palette($primary, A700, 30%);

      .material-icons {
        background: var(--accent-700);
        color: var(--accent-700-contrast);
        margin-right: 4px;
        margin-left: -2px;
      }
    }
  }

  & > b > .variable {
    font-weight: bold;
  }

  > div {
    word-break: break-word;
  }
}

.cdk-overlay-backdrop {
  &.no-backdrop-filter {
    backdrop-filter: none;
  }

  &.transparent-backdrop {
    background: transparent;
  }
}

input.simple-input {
  padding: 6px 8px;
  height: 100%;
  outline-width: 0;
  color: var(--foreground-text);
  border-radius: $border-radius;
  border: 1px solid rgba(var(--foreground-divider-rgb), 14%);
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: var(--foreground-divider);
  }
}

.correct-hidden-badge > .mat-badge-content {
  display: none;
}

// accent: $accent
@mixin mat-btn-light-stroked($class-suffix, $color) {
  &.mat-#{$class-suffix} {
    border-color: mat.get-color-from-palette($color, default, 40%);
  }
}

button.mat-stroked-button.light-stroke {
  @include mat-btn-light-stroked("primary", $primary);
  @include mat-btn-light-stroked("accent", $accent);
  @include mat-btn-light-stroked("warn", $warn);
}

.global-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.center-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-icon,
mat-icon {
  @for $i from 12 to 260 {
    @if $i % 2 {
      &.mat-#{$i} {
        @include mat-icon-size(#{$i}px);
      }
      &.mat-#{$i}-force {
        @include mat-icon-size(#{$i}px, $force: true);
      }
    }
  }
}

.box-style-2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  padding: 0 2%;
  border-radius: 4px;
  background: #fff;
  box-shadow: inset 0 0 0 2px var(--foreground-divider);

  &.inset-shadow {
    box-shadow: inset 0 0 0 2px var(--foreground-divider);
  }

  > .left-actions,
  > .right-actions {
    display: flex;
    align-items: center;
  }

  > .left-actions {
    > .action {
      display: flex;
      position: relative;
      padding-right: 7px;

      &:not(:first-child) {
        padding-left: 7px;

        &::before {
          content: "";
          position: absolute;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 60%;
          background-color: var(--foreground-divider);
        }
      }
    }
  }

  .right-actions {
    gap: 12px;
  }

  .search-input-wrapper {
    margin: 0 11px;

    &--local {
      margin-right: 10px;
    }

    .input-container {
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;

      > mat-icon,
      > .clear-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      > mat-icon {
        left: 12px;
      }

      > .clear-button {
        right: 0;
        width: 36px;
        height: 36px;
        line-height: 36px;
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.2s ease;
        transform: translate(100%, -50%);

        //Centralizes all search clear 'x's
        display: flex;
        align-items: center;
        justify-content: center;

        &.visible {
          transform: translate(0, -50%);
          opacity: 1;
        }
      }

      > .simple-input {
        padding: 8px 34px;
        width: 100%;
        height: 38px;
        margin: 0;
        background: rgba(var(--foreground-text-rgb), 6%);
      }

      & > mat-progress-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }

  .load-controls-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  app-enum-picker .button-mode-button {
    margin: 0 8px;
    height: 38px;
  }

  app-ns-picker {
    margin-left: 6px;
    margin-right: 6px;
    box-shadow: inset 0 0 0 1px rgba(var(--primary-50-rgb), 40%);
  }

  @include mq('sm', true) {
    margin-bottom: 4px;

    .right-actions {
      gap: 0px;
    }

    .ation {
      &:not(:first-child) {
        padding-inline: 4px;
      }
    }
    &.mobile {
      padding: 0;
      box-shadow: none;


      .search-input-wrapper {
        width: 100%;
        margin: 0;

        .input-container {
          width: 100%;
        }
      }
    }
  }
}

.chip-list-no-wrap {
  .mat-chip-listbox-wrapper {
    flex-wrap: nowrap;
  }
}

mat-progress-bar {
  &.search-debounce-1s {
    > div {
      .mat-progress-bar-fill {
        transition: 1s ease;
      }
    }
  }
}

.tag-container.collapsed {
  .tags-list.not-modal {
    overflow: hidden;
  }
}

app-conditional-expansion-panel {
  &.flat {
    mat-expansion-panel {
      box-shadow: none !important;
      background: rgba(var(--primary-default-rgb), 8%);
    }
  }
}

.disable-body-content-scroll {
  .mat-tab-body-content {
    overflow: hidden;
  }
}

.mat-tab-padding-2x {
  .mat-tab-body-content {
    padding: 2px;
  }
}

.cdk-overlay-pane.generic-home-json-viewer {
  .mat-dialog-container {
    background-color: transparent;
    backdrop-filter: blur(4px);

  }
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-surface {
    padding: 0;
  }
}

app-non-serializable-taggable-editor {
  &.reverse-icon-color {
    .mat-button.mat-primary,
    .mat-icon-button.mat-primary {
      color: #fff;
    }
  }
}

app-non-serializable-taggable-editor {
  &.inherit-color {
    .mat-button.mat-primary,
    .mat-icon-button.mat-primary {
      color: inherit;
    }
  }
}

.embbed-first-form {
  height: 100%;

  > .container {
    max-height: 100% !important;
  }
}

.card-grid {
  display: flex;
  gap: 8px;
}

.sep-labaled {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $smaller-font;
  text-transform: uppercase;
  padding: 8px 0;
  margin: 8px 0;
  color: get-color-from-pallete($colmeia-foreground, text, 70%);
  font-weight: bold;
  gap: 8px;
  white-space: nowrap;

  &.no-text-wrap {
    white-space: initial;
    text-align: center;
  }

  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: get-color-from-pallete($colmeia-foreground, divider, 14%);
  }
}

.highlighted {
  outline: #fffb009c;
  background: #fffb0080 !important;
  outline-width: 4px;
  outline-style: auto;
}

.highlighted-accent {
  outline: 1px solid var(--accent-500) !important;
  background: rgba(var(--accent-500-rgb), 0.3) !important;
  color: var(--accent-500-contrast) !important;
}

.pagination-menu{
  margin-left: 16px;
}
