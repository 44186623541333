@use '@angular/material' as mat;
.landing-button-base {
  border: 2px solid;
  padding: 8px 0;
  text-align: center;
  border-radius: 40px;
  width: 200px;
  text-transform: uppercase;
  margin: 20px auto 0;
  letter-spacing: 1.5px;
  font-weight: bold;
  display: block;
}

.landing-main-button {
  @extend .landing-button-base;

  width: 180px;
  font-size: 0.86rem;
  text-transform: uppercase;
  height: 48px;
  background-color: var(--accent-default);
  color: var(--accent-default-contrast);
  border: 0;

  &.disabled {
    opacity: 0.6;
  }
}

.landing-secondary-button {
  @extend .landing-button-base;

  font-size: 0.60rem;
  background-color: var(--accent-600-contrast);
  color: var(--accent-600);
}

.landing-input {
  &.mat-mdc-form-field-appearance-fill {
    .mat-mdc-floating-label.mdc-floating-label--float-above {
      color: var(--accent-default);
    }
  }

  &.mat-focused {
    .mdc-line-ripple {
      background-color: var(--accent-default);
    }
  }
}
