@use '@angular/material' as mat;
$border-color: var(--foreground-divider);
$border: 1px solid $border-color;

$from-center: 18px;
$default-padding: 4px;

.data-mapping-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.cdk-drop-list-dragging {
  .data-mapping-row {
    &:not(.cdk-drag-preview){
      transition: 250ms;
    }

    .dm-actions {
      display: none;
    }
  }
}

.data-mapping-row {
  display: flex;
  width: 100%;
  position: relative;
  border-radius: $border-radius;
  background-color: var(--foreground-background);
  color: var(--foreground-text);
  overflow: hidden;

  &:not(.header) {
    border: $border;
    margin-bottom: 8px;
  }

  .direction-indicator,
  &:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }

  &:not(.header) {
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: $border-color;
      z-index: 1;
    }
  }

  .direction-indicator {
    font-family: 'Material Icons';
    font-feature-settings: 'liga';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--primary-default);
    background: var(--primary-default-contrast);
    border-radius: 50%;
    font-size: 24px;
    border: $border;
    z-index: 2;
    transition: .2s ease-in-out;
    cursor: default;
  }

  &.header{
    cursor: default;
    .dm-column {
      text-transform: uppercase;
      font-size: $smaller-font;
      font-weight: bold;
      color: var(--primary-default);
      padding: $default-padding 28px $default-padding $default-padding;

      & + .dm-column {
        padding: $default-padding $default-padding $default-padding 28px;
      }
    }
  }

  .dm-column-base {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .dm-column {
    @extend .dm-column-base;

    padding: $default-padding $from-center $default-padding $default-padding;

    & + .dm-column {
      padding: $default-padding $default-padding $default-padding $from-center;
    }

    .dm-info-source {
      margin: 0;
      padding: 6px 8px;
      border-radius: $border-radius;
      transition: .2s ease-in-out;
      outline: none;
      border: none;
      flex-grow: 1;
    }

    .openTracker {
        border-radius: 50px;
        width: 36px;
        height: 36px;
        transition: .2s ease-in-out;

        &:hover {
            background-color: rgba(0,0,0, .1);
        }
    }

    input.dm-info-source {
      &:focus,
      &:hover {
        background: rgba(var(--foreground-text-rgb), 8%);
      }
    }
  }

  .dm-actions {
    height: 100%;
    position: absolute;
    top: 0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: .2s ease-in-out;
    background: linear-gradient(90deg, transparent, var(--foreground-background));

    button {
      width: 36px;
      height: 36px;
      &:hover {
        background-color: rgba(0,0,0, .1);
      }
    }

    mat-icon {
      @include mat-icon-size(24px);
    }
  }

  &:hover {
    .dm-actions {
      transform: translateX(0);
    }
    .openTracker {
      position: relative;
      right: 36px;
    }
  }

  &.ordenable-item-common {
    &:not(.header) {
      .direction-indicator:before {
        @extend .direction-indicator;
        content: "drag_handle";
        opacity: 0;
      }

      &:hover {
        .direction-indicator {
          color: transparent;
          cursor: move;

          &:before {
            opacity: 1;
            color: var(--primary-default);
            font-size: 18px;
          }
        }
      }
    }

    &.header {
      .direction-indicator {
        display: flex;
        content: "drag_indicator";
        background: transparent;
        font-size: 18px;
        border: none;
      }
    }
  }
}
