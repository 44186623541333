.cron-job-pattern-generator {
    .react-js-cron {
        display: flex;
        gap: 12px;
        align-items: center;

        .react-js-cron-field {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        div {
            display: flex;
            align-items: center;
        }
    }
}
