
$color-levels: default, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;

@mixin apply-color-levels-basic($color) {
  @each $level in $color-levels {
    @include create-basic-tag($color, $level);
  }
}

@mixin create-basic-tag($color, $variant) {

    &.app-#{$variant} {

        color: var(--#{$color}-#{$variant}) !important;

        mat-icon {
            color: var(--#{$color}-#{$variant}) !important;
        }
    
        &.app-invert {
            color: var(--#{$color}-#{$variant}-contrast) !important;
    
            mat-icon {
                color: var(--#{$color}-#{$variant}-contrast) !important;
            }
        }
    }
}

@mixin apply-color-levels-flat($color) {
    @each $level in $color-levels {
      @include create-flat-tag($color, $level);
    }
  }

@mixin create-flat-tag($color, $variant) {



    &.app-#{$variant} {

        background-color: var(--#{$color}-#{$variant});
        color: var(--#{$color}-#{$variant}-contrast) !important;

        mat-icon {
            color: var(--#{$color}-#{$variant}-contrast) !important;
        }

        &.app-invert {
            background-color: var(--#{$color}-#{$variant}-contrast);
            color: var(--#{$color}-#{$variant}) !important;

            mat-icon {
                color: var(--#{$color}-#{$variant}) !important;
            }

        }

    }
}


@mixin apply-color-levels-stroked($color) {
    @each $level in $color-levels {
      @include create-flat-stroked($color, $level);
    }
  }

@mixin create-flat-stroked($color, $variant) {



    &.app-#{$variant} {
        box-shadow: inset 0 0 1px 1px var(--#{$color}-#{$variant});
        color: var(--#{$color}-#{$variant}) !important;

        mat-icon {
            color:  var(--#{$color}-#{$variant}) !important;
        }

        &.app-invert {
            box-shadow: inset 0 0 1px 1px var(--#{$color}-#{$variant}-contrast);
            color: var(--#{$color}-#{$variant}-contrast) !important;
    
            mat-icon {
                color:  var(--#{$color}-#{$variant}-contrast) !important;
            }
        }

    }
}


@mixin build-tag-classes(
  $color,
) {
    &.app-#{$color}{

        &.app-basic {
            @include apply-color-levels-basic($color);
        }
        
        &.app-flat {
            @include apply-color-levels-flat($color)
        }
    
        &.app-raised {
            color: var(--#{$color}-default) !important;

            mat-icon {
                color: var(--#{$color}-default) !important;
            }
        }
    
        &.app-stroked {
            @include apply-color-levels-stroked($color)

        }
    
        &.app-light {
            background-color: var(--#{$color}-default);
            color: var(--#{$color}-default-contrast) !important;

            mat-icon {
                color: var(--#{$color}-default-contrast) !important;
            }
        }
    }
}

.app-tag-base {
    padding: 2px 6px;
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;

    @include build-tag-classes('primary');
    @include build-tag-classes('accent');
    @include build-tag-classes('info');
    @include build-tag-classes('success');
    @include build-tag-classes('alert');
    @include build-tag-classes('warn');
}
