.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: var(--primary-300);
}

.mat-slide-toggle.mat-accent.mat-checked .mat-slide-toggle-bar {
    background-color: var(--accent-200);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
    background-color: var(--warn-200);
}

.mat-calendar-body-in-range::before {
    background: var(--primary-50);
}

@media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: var(--primary-100);
    }
}