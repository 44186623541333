
.menu-itens-complete {
    position: relative;
    

    .swiper {
        position: unset;
        width: 230px;
    }
    .swiper-button-prev {
        left: 1px
    }
    .swiper-button-next {
        right: 1px;
    }
    .swiper-button-prev,
    .swiper-button-next {
        background-color: white;
        border-radius: 50px;
        height: 48px;
        width: 48px;
        color: #65676B;
        outline: 1px solid #65676b40;
        &::after {
            font-size: 16px;
            color: #65676B;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }
        &:hover {
            background-color: rgb(247, 247, 247);
        }
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-slide {
        width: 180px;
        min-height: 111px;
    }
}