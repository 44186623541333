@use '@angular/material' as mat;
@import 'vendors/material/theme_variables';

.access-control-screen.ordenable-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  padding: 12px 14px;
  transition: opacity .2s ease-in-out, transform 250ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(var(--foreground-text-rgb), 20%);
  
  &:not(.acs-header) {
    opacity: 0.8;
  }

  &:hover:not(.cdk-drag-placeholder) {
    opacity: 1;
  }

  .text-content, .actions {
    display: flex;
    align-items: center;
  }

  > .text-content {
    > mat-icon {
      @include mat-icon-size(18px);
      margin-right: 10px;
    }
  }

  &.cdk-drag-preview {
    opacity: 0.9;
  }

  &.cdk-drag-placeholder {
    opacity: 0.4;
  }

  &.acs-header {
    cursor: default;
    background: transparent;
    font-weight: bold;
    box-shadow: none;
  } 
}