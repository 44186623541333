%checkbox {
    display: none;
}

.checkbox {
    @extend %checkbox;
    cursor: pointer;
    & + label {
        cursor: pointer;
    }
    & + label span {
        display: inline-block;
        min-width: 19px;
        width: 19px;
        height: 19px;
        margin: -2px 10px 0 0;
        vertical-align: middle;
        // background: url('/../../../../assets/images/check_radio_sheet.png') left top no-repeat;
        cursor: pointer;
        position: relative;
        top: 3px;
    }
    & + label span {
        // background: url('/../../../../assets/images/check_radio_sheet.png') -18px top no-repeat;
    }
}
