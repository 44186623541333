
%i {
  vertical-align: middle;
}

.i {
  &--sm {
    @extend %i;
    font-size: 16px; // 15x15px
  }
  
  &--xsm {
    @extend %i;
    font-size: 22px; 
  }

  &--md {
    @extend %i;
    font-size: 24px;
  }

  &--slg {
    @extend %i;
    font-size: 28px;
  }

  &--mdlg {
    @extend %i;
    font-size: 30px;
  }

  &--lg {
    @extend %i;
    font-size: 34px; // 20x20
  }

  &--lgg {
    @extend %i;
    font-size: 40px; // 20x20
  }
}

// i {
//   color: $white;
// }

// button {
//   & > i {
//     mix-blend-mode: difference;
//   }
// }
