
app-display-fields-editor {

    .l-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }
}


.display-fields-container {
    display: flex;
    max-width: 500px;
    flex-direction: column;

    &.cdk-drop-list-dragging .display-config-item-container:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
}


.display-config-item-container {
    padding: 0 2px;
    flex: 1;
    position: relative;

    .advcd-menu-sub-title {
        font-size: 1rem;
        margin-top: 1.6rem;
    }

    .config-item {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px;
        margin-top: 8px;
        position: relative;

        .drag-handle {
            cursor: grab;
            display: flex;
            align-items: center;
            padding: 0.5rem;
        }

        > .label {
            margin: 0;
        }

        > .actions {
            margin-left: auto;
            display: flex;
            align-items: center;

            [mat-icon-button] {
                margin-left: 6px;
            }
        }

        .prop-name {
            padding: 4px 6px;
            border-radius: var(--border-radius);
            font-size: 12px;
            font-weight: 500;
            background: rgba(var(--primary-default-rgb), 20%);
            color: rgba(var(--primary-default-rgb), 100%);
        }
        
        .delete-button {
            width: 32px;
            height: 32px;
            min-width: 32px;
            line-height: 32px;
        }

    }

    &.cdk-drag-placeholder {
        opacity: 0;
    }

    &.cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    
}