@use '@angular/material' as mat;
@import 'utilities/variables';
@import 'vendors/material/theme_variables';

.ce-has-bottom-collision {
  transform: translateY(-100%);
}

.type-list-items-container {
  max-width: 420px;
  max-height: 260px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: $border-radius;
  transition: .3s ease-in-out;

  .type-list-items-list {
    overflow: auto;
    min-width: 220px;
    margin: 6px 0;
  }

  .type-list-item {
    width: 100%;
    padding: 6px 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;

    .input-container {
      position: relative;

      & > input {
        padding: 10px 12px;
        padding-right: 38px;
        line-height: 100%;
        width: 100%;
        border: 1px solid transparent;
        border-radius: $border-radius;
        outline-width: 0;
        transition: .2s ease-in-out;
        color: var(--foreground-text);

        &:focus {
          border-color: rgba(var(--foreground-divider-rgb), 60%);
        }

        &.no-hover {
          pointer-events: none
        }
      }

      & > mat-icon {
        @include mat-icon-size(18px);
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: var(--primary-default);
        transition: .2s ease-in-out;
        opacity: 0.4;
        user-select: none;
        cursor: text;
      }

      &.focused,
      &:hover {
        & > mat-icon {
          opacity: 1;
        }
      }

      &:hover {
        > input {
          background-color: rgba(var(--foreground-text-rgb), 5%);
          border-color: var(--foreground-divider);
        }
      }
    }
  }

  .action-token {
    width: 30px;
    height: 30px;
    margin: 0 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: var(--primary-default);
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
    border-radius: $border-radius;

    &.save-changes {
      border-color: rgba(var(--primary-default-rgb), 30%);
    }

    > mat-icon {
      @include mat-icon-size(18px);
    }
  }

  .insert-token-container {
    padding: 8px 10px;

    > input {
      padding: 10px 6px;
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid var(--foreground-divider);
      color: var(--foreground-text);
      outline-width: 0;
      transition: border-color 0.2s ease-in-out;

      &:focus {
        border-color: rgba(var(--foreground-divider-rgb), 60%);
      }
    }

    mat-hint {
      margin-top: 8px;
      display: block;
      font-size: $smaller-font;
      color: rgba(var(--foreground-text-rgb), 70%);
    }
  }
}
