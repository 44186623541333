// **********************************************
// 
//                  DEFAULT HEXAGON( OLD LEGACY )
// 
// **********************************************
// It's used throughout the whole application
// 
.hexagon-shadow {
  width: 70vmin;
  height: 70vmin;
  // -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  // filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  /* border: 2px solid red; */
  background-position: center 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  &:focus {
    outline: none
  }
  .hexagon {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    // clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    .inside {
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      // clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
      background-size: cover;
      background-size: cover !important;
      background-color: #262729;
      background-position: 50% 50% !important;
      img {
        object-fit: contain;
      }
    }
  }
}

// Esses selectors tão uma bosta TODO
.more {
  .hexagon-shadow {
    .hexagon {
      .inside {
        background-size: 50% !important;
        background-color: #232426;
        background-position: 50% 50% !important;
        background-repeat: no-repeat;
      }
    }
  }
}

// Esses selectors tão uma bosta TODO
.chat-icon {
  .hexagon-shadow {
    .hexagon {
      .inside {
        background-size: 50% !important;
        background-color: #232426;
        background-position: 50% 50% !important;
        background-repeat: no-repeat;
      }
    }
  }
}

.hexagon-medium {
  @include hexagon-size(65);
}

.hexagon-small {
  @include hexagon-size(40);
}

.hexagon-xsmall {
  @include hexagon-size(33);
}

.hexagon-50 {
  @include hexagon-size(50);
}

.hexagon-150 {
  @include hexagon-size(150);
}

.hexagon-80{
    @include hexagon-size(80);
}
.hexagon-200{
    @include hexagon-size(200);
}
.hexagon-120 {
  @include hexagon-size(120);
}

.hexagon-200 {
  @include hexagon-size(200);
}

.hexagon-230 {
  @include hexagon-size(230);
}

.selected-participants {
  &__item {
    @include flexed($dir: column, $align: center);
    position: relative;
    margin-right: 10px;

    & > span {
      margin-bottom: 0;
    }

    & > button {
      position: absolute;
      right: -4px;
      line-height: 0;
    }

    & > hexagon {
      margin-top: 18px;
    }
  }
}


// NEW STYLE
%hexagon {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: $white;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

%hexagon--shadow {
  filter: drop-shadow($shadow-terciary);
  padding: $pad-zero;
  background-position: center 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

%inner {
	position: absolute;
	top: 6%;
	left: 6%;
	right: 6%;
	bottom: 6%;
	clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	background-size: cover;
	background-color: $dark-grey;
	background-position: 50% 50% !important;
}

.hexagon {
  &--shadow {
    @extend %hexagon--shadow;
	}

	&--body {
    @extend %hexagon;
	}

	&--inner {
    @extend %inner;
	}

  &__border {
    @extend %hexagon;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: $white;
  }

  &__inner {
    @extend %hexagon;
    top: 3%;
    left: 3%;
    right: 3%;
    bottom: 3%;
    position: absolute;
    background-size: cover;
    background-color: $dark-grey;
    background-position: 50% 50%;
  }
  // 1
  &--xxs {
    width: 24px;
    height: 26px;
  }
  // 2 
  &--xs {
    width: 32px;
    height: 35px;
  }
  &--smd {
    width: 48px;
    height: 53px;
  }
  &--xsmd {
    width: 60px;
    height: 64px;
  }
  &--lsmd {
    width: 90px;
    height: 96px;
  }
  // 5
  &--sm {
    width: 93px;
    height: 100px;
  }
  // 6
  &--xmd {
    width: 32px;
    height: 35px;
  }
  // 7
  &--md {
    width: 120px;
    height: 130px;
  }
  // 8
  &--lg {
    width: 145px;
    height: 155px;
  }
  // 9
  &--xlg {
    width: 168px;
    height: 180px;
  }

  &--xxlg {
    width: 235px;
    height: 252px;
  }
}
