// ======================================
//  Partial Imports
//  OBS: base styles are being imported after due backwards to compatibility
// ======================================

// Utilities
@import "utilities/variables", "utilities/variables2", "utilities/mixins",
  "utilities/functions";

// OLD - Styles(must remove this block!)
@import "helpers/helpers";
@import "vendors/module";

// Base Styles
@import "base/reset", "base/base";

// Layout Styles
@import "layout/module";


// Component Styles
@import "components/i";
@import "components/buttons";
@import "components/modals";
@import "components/inputs";
@import "components/paragraph";
@import "components/checkbox";
@import "components/select";
@import "components/scroll";
@import "components/_vertical-line";
@import "components/diagram-editor";
@import "components/message-instance";
@import "components/dashboard-analytcs";
@import "components/functions-create.component";
@import "components/new-notifications";
@import "components/new-conditions-editor";
@import "components/conditions.editor";
@import "components/bot-event-modal";
@import "components/meta-engagement-dialog";
@import "components/generic-dashboard-edit";
@import "components/graph-viewer";
@import "components/alerts";
@import "components/crm-help-desk.component";
@import "components/conversation-view.scss";
@import "components/conditions-editor.scss";
@import "components/var-inserter.scss";
@import "components/dashboard-home-list.scss";
@import "components/dashboard-home-edit.scss";
@import "components/users-sn-pages.scss";
@import "components/generic-card.scss";
@import "components/var-editor.scss";
@import "components/kb-prediction-modal.scss";
@import "components/kanban-viewer.scss";
@import "components/crm-service-tickets-view.component.scss";
@import "components/display-fields-editor.component.scss";
@import "components/app-tag.component.scss";
@import "components/crm-ticket-protocol-view.component-global.scss";
@import "components/cron-job-pattern-generator.component-global.scss";
@import "components/macros-listing.scss";

// Foundation Styles
@import "foundation/directives.scss";

// Component global adjustments
@import "global/main.scss";
@import "mat-overrides/main.scss";

@import "pages/main.scss";

@import "components/swiper";
@import "components/multimedia-viewer-dialog-panel";

@import "components/menu-fb-template.scss";
@import "mat-corrections.scss";


// Imports made after angular 17
@import '@angular/cdk/overlay-prebuilt.css';
@import "nouislider/dist/nouislider.css";

body {
  --border-radius-raw: $border-radius-raw;
  --border-radius-raw-2x: $border-radius-raw-2x;
  --border-radius: #{$border-radius-raw}px;
  --border-radius-2x: #{$border-radius-raw-2x}px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
