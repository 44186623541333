.ngx-charts {
  .bar:hover {
    stroke: black;
  }
}

ngx-charts-legend.chart-legend .legend-label {
  color: black !important;

  &:hover {
    font-weight: 500;
    transform: scale(1.05);
  }
}
