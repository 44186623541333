@use '@angular/material' as mat;
@use 'sass:color';
@import 'lib';


@mixin stroked-style($size, $force: false) {
  width: $size if($force, !important, null);
  height: $size if($force, !important, null);
  font-size: $size if($force, !important, null);
  line-height: $size if($force, !important, null);
}

@mixin stroked-style($inset: false) {
  box-shadow:  if($inset, inset, null) 0 0 1px 1px var(--foreground-divider) !important;
}

mat-accordion {
  &.bordered {
    mat-expansion-panel {
      @include stroked-style();
    }
  }

  &.stroked-inset {
    mat-expansion-panel {
      @include stroked-style(true);
    }
  }
}

mat-expansion-panel {
  &.stroked {
    @include stroked-style();
  }

  &.stroked-inset {
    @include stroked-style(true);
  }
}

mat-expansion-panel {
  &.overflow-visible {
    overflow: visible;

    .mat-content {
      overflow: visible;
    }

    .mat-expansion-panel-body {
      overflow: hidden;
    }
  }  

  &.skeleton-mode {
    background: transparent;
    border-radius: 0;
    box-shadow: none !important;

    > mat-expansion-panel-header,
    > .mat-expansion-panel-content > .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.glass-style {
    border: 1px solid color.scale(mat.get-color-from-palette($primary, default, 80%), $lightness: +40%);
    background: color.scale(mat.get-color-from-palette($primary, default, 20%), $lightness: +40%);
  }

  &.slim-format {
    .mat-expansion-panel-body {
      padding: 0px 8px 6px;
      line-height: 30px;
    }
  }

  &.no-padding {
    padding: 0;
  }
}


mat-expansion-panel-header {

  &.settings-header {
    min-height: 64px;
    height: auto;
    padding: 12px 24px;
  }

  mat-panel-title {
    .title-as-input {
      height: 38px;
      width: 320px;
      position: relative;
      padding-top: 2px;

      > input {
        width: 100%;
        height: 100%;
        padding-left: 34px;
        border-color: transparent;

        &:hover {
          border-color: rgba(var(--foreground-divider-rgb), 14%);
        }

        &:focus {
          border-color: var(--foreground-divider);
        }
      }

      > mat-icon {
        @include mat-icon-size(18px);
        color: var(--primary-default);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
      }
    }
  }

  &.dense {
    height: 48px !important;

    > .mat-expansion-indicator {
      &::after {
        transform: rotate(45deg) translate(-2px);
      }
    }
  }
}

mat-panel-description[align="end"] {
  justify-content: flex-end;
}
