@import 'utilities/variables';

@mixin hover {
  cursor: pointer;
  &:hover {
      opacity: 0.5;
  }
}
// =========================================
//  Mixins
// =========================================
//OLD LEGACY CODE
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  &:focus, &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active, &.active, .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);

    &:hover, &:focus, &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &:active, &.active, .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled, &[disabled], fieldset[disabled] & {
    &, &:hover, &:focus, &.focus, &:active, &.active {
      background-color: $background;
      border-color: $border;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
}
// Mixin to create a bootstrap button with custom colors
@mixin bootstrap-button($background) {
  $color: #fff;
  border:$background;
  $border: 5%;
  @if (lightness($background) >= lightness(#aaa)) {
    $color: #333;
    $border: .2 * lightness($background);
  }
  @include button-variant($color, $background, darken($background, $border));
}
@mixin screen-size($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-xsmall) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: ($break-xsmall + 1)) and (max-width: ($break-medium - 1)) { @content; }
  }
  @else if $media == large-screens {
    @media only screen and (min-width: ($break-medium - 1)) and (max-width: ($break-large - 1)) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: ($break-large + 1)) and (max-width: ($break-xlarge - 1)) { @content; }
  }
  @else if $media == xlarge-screens {
    @media only screen and (min-width: $break-xlarge) { @content; }
  }
}
/** Mixin for layout + OR - 1024*/
@mixin layout-size($media) {
  @if $media == small-size {
    @media only screen and (min-width: $break-xsmall) and (max-width: ($break-medium - 1)) { @content; }
  }
  @else if $media == medium-size {
    @media only screen and (min-width: $break-medium ) and (max-width: ($break-large - 1)) { @content; }
  }
  @else if $media == large-size {
    @media only screen and (min-width: $break-large ) and (max-width: ($break-xlarge - 1)) { @content; }
  }
}
/** Mixin for layout + OR - 1024*/
@mixin menu-branding($media) {
  @if $media == small-size {
    @media only screen and (min-width: $break-xsmall) and (max-width: ($max-width-lg - 1)) { @content; }
  }
}
@mixin hexagon-size($size) {
  max-width: $size * 0.92px;
  max-height: $size*1px;
}
@mixin button-size($btn) {
  @if $btn == small {
    padding: 7px
  }
  @else if $btn == medium {
    padding: 10px
  }
  @else if $btn == large {
    padding: 10px
  }
}

// New Mixins
// Center containers
@mixin centered($w) {
  width: $w;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

// Rounded elements
@mixin rounded($dim, $brdr: null) {
  width: $dim;
  height: $dim;
  border: $brdr;
  border-radius: 50%;
}

// Create a flex container
@mixin flexed(
  $disp: flex,
  $dir: null,
  $wrap: null,
  $just: null,
  $align: null) {
  display: $disp;
  flex-direction: $dir;
  flex-wrap: $wrap;
  justify-content: $just;
  align-items: $align;
}

// Media queries
@mixin mq($break, $max: false) {
  $value: map-get($breakpoints, $break);
  $sm: map-get($breakpoints, 'sm');

  @if $value == null {
    @error "`#{$break}` is not a valid breakpoint name.";
  }

  @else if ($value < $sm or $max) {
    @media (max-width: $value) {
      @content;
    }
  }
  @else {
    @media (min-width: $value) {
      @content;
    }
  }
}

@mixin mat-icon-size($size, $force: false) {
  width: $size if($force, !important, null);
  height: $size if($force, !important, null);
  font-size: $size if($force, !important, null);
  line-height: $size if($force, !important, null);

  --mat-menu-item-icon-size: $size if($force, !important, null);
}

@mixin full-centered-flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin nice-scroll(
  $size: 6px,
  $background: transparent,
  $color: rgb(0 0 0 / 30%),
  $color-hover: rgb(0 0 0 / 45%),
  $border-radius: 4px
  ) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
      background: $background;
  }

  &::-webkit-scrollbar-thumb {
      background: $color;
      border-radius: $border-radius;

      &:hover {
        background: $color-hover;
      }
  }

  &::-webkit-scrollbar-corner {
    background: $background;
  }
}
