@use '@angular/material' as mat;
@import 'lib';

app-functions-create {
  .is-created.is-custom {
    app-non-serializable-taggable-editor {
      .mat-button.mat-primary, .mat-icon-button.mat-primary {
        color: #fff;
      }
    }

    .context-selector {
      > mat-button-toggle {
        color: rgb(255 255 255 / 42%);
      }
    }
  }

  .move-window-trigger {
    position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      background: transparent;
      transform: translate(-50%, -50%);
      cursor: move;
  }

  .context-selector {
    border: 0;
    box-shadow: inset 0 0 1px 1px rgba(var(--accent-default-rgb), 17%);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: inset 0 0 1px 1px var(--accent-default);
    }

    > mat-button-toggle {
      background: transparent;
      margin: 2px;
      border-radius: $border-radius;
      border: 0 !important;

      &.mat-button-toggle-checked {
        color: var(--accent-darker-contrast) !important;
        background-color: var(--accent-default);
      }

      > button {
        text-transform: uppercase;
        font-weight: bold;
        font-size: $smaller-font;
      }
    }
  }
}


.cdk-global-overlay-wrapper.functions-window {
  .dialog-window {

    &.is-custom-function {
      &.focused .mat-dialog-container,
      &.dragging .mat-dialog-container,
      &.moving .mat-dialog-container,
      .mat-dialog-container {
        border: 0;
        box-shadow:
            0px 25px 36px -24px rgb(55 65 88 / 57%),
            inset 0px 0px 0px 0px #374158;
      }
    }

    .dashboard-page .window-content {
      margin: 0;
      padding: 0;
    }

    &:before {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
}

.user-function-tooltip {
  font-size: $smaller-font;
  font-weight: bold;
  box-shadow: inset 0 0 0 1px rgba(var(--accent-default-rgb), 70%), 0 0 10px rgba(var(--accent-default-rgb), 40%);
}


.user-function-artefact {
  border-radius: 4px;
  padding: 2px 0;
  background: rgb(0 0 0 / 48%);

  &-name {
    @extend .user-function-artefact;
    color: #00daff !important;
    text-shadow: 0 0 8px #3affebd9;
  }

  &-loading {
    @extend .user-function-artefact;
    color: transparent !important;
    text-shadow: 0 0 8px #fff;
    position: relative;

    &:before {
      content: "◔";
      font-size: 11px;
      color: #fff;
      position: absolute;
      animation: spin 1s linear infinite;
      line-height: 18px;
      top: 0;
    }
  }

  &-error {
    @extend .user-function-artefact;
    color: #ff2222 !important;
    text-shadow: 0 0 8px #ff3a3ad9;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
  }

  &.mtkw {
    display: none;
  }

}
