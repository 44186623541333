@use '@angular/material' as mat;
@use 'sass:color';
@import 'utilities/variables';
@import 'vendors/material/theme_variables';

$detractor-color: color.scale(mat.get-color-from-palette($warn, default), $lightness: +10%); // red
$passive-color: rgba(var(--foreground-secondary-text-rgb), 60%); // cinza
$promoters-color: var(--success-900); // verde

.metaengagement-dialog-nps {
  --insuficient-color: $detractor-color;
  --inconclusive-color: $passive-color;
  --conclusive-color: $promoters-color;

  nouislider {
    height: 40px;
    margin-top: 48px;
    margin-bottom: 0;
    padding:0 10px;
  }

  mat-expansion-panel-header {

    mat-icon {
      &.insuficient {
        color: $detractor-color;
      }

      &.inconclusive {
        color: $passive-color;
      }

      &.conclusive {
        color: $promoters-color;
      }
    }
  }

  .noUi-handle {
    border-radius: 50%;
    cursor: move;
    border-color: var(--primary-default-contrast);
    background-color: var(--primary-default);
    box-shadow: none;

    &:after, &:before {
      display: none;
    }
  }

  .noUi-horizontal {
    height: 6px;

    .noUi-handle {
      width: 18px;
      height: 18px;
      right: -9px;
      top: -7px;
    }
  }

  .noUi-tooltip {
    border: none;
    border-radius: 0;
    background: transparent;
    color: var(--primary-default-contrast);
    font-size: $smaller-font;
    font-weight: 500;
    margin-bottom: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      background: var(--primary-default);
      z-index: -1;
      border-radius: 100%;
      border-bottom-right-radius: 0;
      transform: rotate(45deg);
      margin-left: -15px;
      margin-top: -15px;
    }
  }

  .noUi-connect:nth-child(1) {
    background-color: $detractor-color;
  }

  .noUi-connect:nth-child(2) {
    background-color: $passive-color;
  }

  .noUi-connect:nth-child(3) {
    background-color: $promoters-color;
  }

  .noUi-marker-large {
    height: 8px;
  }

  .noUi-value {
    margin-top: 4px;
    font-size: $smaller-font;
    font-weight: bold;
  }

  .noUi-connects {
    box-shadow: 0 0 1px 1px var(--primary-default);
  }

  nouislider.invert {
    .noUi-connect:nth-child(1) {
      background-color: $promoters-color;
    }

    .noUi-connect:nth-child(2) {
      background-color: $passive-color;
    }

    .noUi-connect:nth-child(3) {
      background-color: $detractor-color;
    }
  }

  .legends-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 1rem;
    margin-top: 48px;

    > .legend-item {
      position: relative;
      padding: 8px 16px 8px 24px;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 4px;
        width: 14px;
        height: 4px;
        border-radius: 2px;
        transform: translateY(-50%);
      }

      &.detractors {
        &::before {
          background-color: $detractor-color;
        }
      }

      &.passives {
        &::before {
          background-color: $passive-color;
        }
      }

      &.promoters {
        &::before {
          background-color: $promoters-color;
        }
      }
    }
  }
}
