%input {
  border: get-item('borders', 'input');
  border-radius: get-item('bradius', 'input');
  box-shadow: get-item('shadows', 'input');
}

.input {

  &--sm {
    @extend %input;
    width: 175px;
  }

  &--md {
    @extend %input;
    width: 260px;
  }
  
  &--lg {
    @extend %input;
    width: 350px;
  }

  &--full {
    @extend %input;
    width: 100%;
  }
  
  &--editable {
      padding: 3px;
      height: 20px;
      border-radius: 0!important;
    &:disabled {
      background: transparent;
      height: 20px;
      box-shadow: none;
      border: 0;
    }
    &:enabled {
      @extend %input;
      box-shadow: none !important;
      background:get-item('colors', 'tooltip');
    }
  }

  &--editable {
      @extend %input;
      width: 100%;
      padding: 3px;
      height: 20px;
      border-radius: 0!important;
    &:disabled {
      background: transparent;
      height: 20px;
    }
    &:enabled {
      background:get-item('colors', 'tooltip');
    }
  }
}
