@use '@angular/material' as mat;
@import 'utilities/variables';
@import 'light-theme';

@include mat.core();

$colmeia-app-typography: mat.define-typography-config($font-family: $font-family);

$app-bar-height-raw: 60;
$app-dashboard-pd-raw: 30;

$app-bar-height: #{$app-bar-height-raw}px;
$app-dashboard-pd: #{$app-dashboard-pd-raw}px;

:root {
  @include mat.all-component-themes($colmeia-light-theme);
  @include mat.all-component-typographies($colmeia-app-typography);
}

.mat-tooltip {
  white-space: pre-line;
}

.mat-tooltip,
.mdc-tooltip,
.mat-mdc-tooltip {
  &.tooltip-line-break {
    white-space: pre-line;

    .mdc-tooltip__surface,
    .mat-tooltip__surface,
    .mat-mdc-tooltip__surface {
      max-width: 300px;
    }
  }
  &.tooltip-pre-line {
    white-space: pre-line;

    .mdc-tooltip__surface,
    .mat-tooltip__surface,
    .mat-mdc-tooltip__surface {
      max-width: 300px;
    }
  }
  &.tooltip-pre {
    white-space: pre;
    
    .mdc-tooltip__surface,
    .mat-tooltip__surface,
    .mat-mdc-tooltip__surface {
      max-width: 360px;
    }
  }
}

// .mat-dialog-container {
//   padding: 15px;
// }

.min-width-400 {
  min-width: 400px;

  @include mq('xs') {
    min-width: 85%;
  }
}