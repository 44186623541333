@use "@angular/material" as mat;
.mat-dialog-container {
  background: var(--foreground-modal-bg);
}

.cdk-overlay-backdrop {
  &.blur-z1 {
    backdrop-filter: blur(1px);
    background: #00000054;
  }
}

.cdk-overlay-pane {
  max-height: 96vh;

  .mat-dialog-container, .mat-mdc-dialog-container {
    overflow-x: hidden;
  }

  .mat-dialog-container, .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 24px;
  
      [mat-dialog-title], [matDialogTitle] {
        padding: 0 !important;
        margin: 0 0 20px;

        &::before {
          display: none;
        }
      }
  
      mat-dialog-content {
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 0;
        padding-top: 0;
      }
  
      mat-dialog-actions {
        min-height: 68px !important;
        padding: 8px 0 !important;
        margin-bottom: -24px !important;
        box-sizing: content-box;
      }
    }
  }

  // usado para a transição ao novo mat dialog
  // &.old-padding {
  //   .mat-mdc-dialog-surface {
  //     padding: 24px;
  //   }
    
  //   .mat-mdc-dialog-content {
  //     margin: 0 -24px;
  //     padding: 0 24px
  //   }
  // }

  &.no-padding {
    .mat-dialog-container, .mat-mdc-dialog-surface {
      padding: 0;
    }
  }

  &.no-bottom-padding {
    .mat-dialog-container, .mat-mdc-dialog-surface {
      padding-bottom: 0;
    }
  }

  &.small-size {
    .mat-dialog-container, .mat-mdc-dialog-container {
      width: 30vw;
      max-width: 1240px;
      min-width: 420px;
    }
  }

  &.medium-size {
    .mat-dialog-container, .mat-mdc-dialog-container {
      width: 50vw;
      max-width: 720px;
      min-width: 420px;
    }
  }

  &.average-size {
    .mat-dialog-container, .mat-mdc-dialog-container {
      width: 70vw;
      max-width: 1240px;
      min-width: 420px;

    }
    .mat-mdc-dialog-surface {
      padding: 24px;
    }
  }

  &.large-size {
    .mat-dialog-container, .mat-mdc-dialog-container {
      width: 80vw;
      min-width: 420px;
    }
  }

  &.transparent-background {
    .mat-dialog-container, .mat-mdc-dialog-container {
      background: transparent;
    }
  }

  &.visible-overflow {
    .mat-dialog-container, .mat-mdc-dialog-container {
      overflow: visible;
    }
  }

  @include mq('sm', true) {
    max-height: 100vh;
    max-width: 90vh;

    &.no-padding,
    &.no-bottom-padding,
    &.small-size,
    &.medium-size,
    &.average-size,
    &.large-size {
    
      .mat-dialog-container {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        max-height: 100vh;
        border: none;
        display: flex;
        flex-direction: column;
      }

    }
    
  }
}

.global-warning-dialog-backdrop {
  background-color: rgb(0 0 0 / 50%);
}

.global-warning-dialog {
  .mat-dialog-container, .mat-mdc-dialog-container {
    max-width: 720px;
    border-radius: #{$border-radius-raw * 2}px;
    
    .mat-mdc-dialog-surface {
      padding: 0;
    }
  }
}
