%select {
    padding: 0 10px;
    height: 30px;
    width: 100%;
    background-position-x: 98%;
    background-size: 14px;
    margin: 0 0 10px 0;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
    border-radius: 3px;
    border: 1px solid #c7c7c7;
}

.select__input {
    @extend %select;

    &:not([multiple]){
        -webkit-appearance:none;
        -moz-appearance:none;
        background-position:98% 50%;
        background-repeat:no-repeat;
        color: get-item('colors', 'text');
        opacity: 0.6
    }
    &:hover {
        cursor: pointer;
    }
    &:focus {
        cursor: pointer;
        outline: none;
        color: get-item('colors', 'text');
        opacity: 1
    }
    /* Hidden placeholder */
    option[disabled]:first-child {
        display: none;
    }
}