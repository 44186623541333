@use "@angular/material" as mat;
@import "lib";

cm-generic-dashboard-edit,
.generic-dashboard-edit {
  display: block;
  height: 100%;

  .box-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .text-n-options {
      width: 100%;
      min-width: 300px;
      display: flex;
      align-items: center;

      .title {
        margin-left: 8px;

        @include mq("sm", true) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  cm-non-serializable-errors-display {
    display: block;
  }

  .box-title .buttons {
    justify-content: flex-end;
    align-items: center;
    // min-width: 260px;
  }

  .box-title .buttons button {
    margin-left: 15px;
  }

  .box-title > div {
    display: flex;
  }

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      min-width: 56px;
    }
  }

  .box-img {
    margin-right: 12px;
  }

  .tagEditor {
    position: relative;
  }

  .input-name-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: $border-radius;

    &:hover {
      background-color: mat.get-color-from-palette(
        $colmeia-foreground,
        text,
        10%
      );
    }

    &.has-change,
    &.focused {
      background-color: transparent;
      border-color: rgba(var(--foreground-text-rgb), 20%);

      & > input {
        transform: translateX(-30px);
      }

      & > .edit-indicator {
        opacity: 0;
      }
    }

    & > input {
      width: calc(100% + 40px);
      font-size: 1.4rem;
      background: transparent;
      outline: 0;
      transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out;
      transform: translateX(0);
      padding: 8px 12px;
      border: 0;
      padding-left: 40px;
      line-height: 100%;
      float: left;
    }

    // &.has-change {
    //   & > input {
    //     width: 100%;

    //   }

    //   &:not(.focused) {
    //     & > input {
    //       padding-right: 40px;
    //     }
    //   }
    // }

    > .edit-indicator,
    > .cancel-button {
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-default);
      transform: translateY(-50%);
      border-radius: $border-radius;
      transition: 0.2s ease-in-out;
      transition-property: transform, opacity;
    }

    > .edit-indicator {
      left: 12px;
      @include mat-icon-size(18px);
    }

    $cancel-button-diameter: 38px;

    > .cancel-button {
      opacity: 0;
      right: 0;
      z-index: -1;
      padding: 0;
      width: $cancel-button-diameter;
      height: $cancel-button-diameter;
      min-width: $cancel-button-diameter;

      mat-icon {
        @include mat-icon-size(18px);
      }

      &.visible {
        z-index: 2;
        opacity: 1;
      }
    }
  }

  app-hexagon-uploader {
    margin: -0.5rem;
  }

  .dashboard-page .header {
    margin-bottom: 28px;

    @include mq("sm", true) {
      margin-bottom: 4px;
    }
  }

  $window-control-button-diameter: 32px;

  .window-control-container {
    height: $window-control-button-diameter;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .window-button {
      width: $window-control-button-diameter;
      height: $window-control-button-diameter;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-default);
      color: var(--primary-default-contrast);

      > mat-icon {
        @include mat-icon-size(16px);
      }

      &:not(:last-child) {
        margin-right: 6px;
      }

      &:hover {
        background-color: var(--primary-800);
      }
    }
  }

  .top-center-container {
    width: 100%;
    padding: 4px 16px;
    min-height: $window-control-button-diameter;
    background: var(--primary-default);
    border-radius: 4px;
    color: var(--primary-default-contrast);
    line-height: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }

    > .title {
      display: inline-flex;
      margin: 0 auto;
      font-size: $smaller-font;
      font-weight: bold;
      text-transform: uppercase;
      // cursor: move;
      // user-select: none;

      .helper-info {
        bottom: 4px;
        left: 4px;
      }
    }
  }

  app-non-serializable-taggable-editor {
    margin: 4px 4px 0 0;
  }

  .more-options {
    position: relative;
    z-index: 2;
  }

  .dashboard-page.window-mode {
    app-non-serializable-taggable-editor {
      margin: 0 4px 0 0;
    }

    .header {
      margin-top: -12px;
      margin-right: -12px;
      margin-left: -12px;

      .top-header {
        flex-direction: row-reverse;
      }

      .window-control-container {
        flex-direction: row-reverse;

        .window-button {
          margin-right: initial;

          &:not(:last-child) {
            margin-left: 6px;
          }
        }
      }
    }
  }

  .save-button {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--accent-default);
      transition: 0.2s ease-in-out;
      opacity: 0;
    }

    &.has-change {
      &:after {
        opacity: 1;
      }
    }
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    box-shadow: 0 0 10px 10px white;

    &:before {
      position: absolute;
      content: "";
      left: -2px;
      bottom: 100%;
      background: white;
      width: calc(100% + 4px);
      height: 30px;
    }
  }

  .gde-alert {
    margin-left: 12px;
  }
}
