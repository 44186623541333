@use "@angular/material" as mat;
@use "sass:color";

app-code-editor {
  ngx-monaco-editor {
    .editor-container {
      height: 100% !important;
    }
  }
}

mat-select.sm-dm-match-all {
  .mat-select-trigger {
    padding: 8px 2px 8px 8px;
  }

  .trigger-label,
  .mat-select-placeholder {
    font-weight: bold;
    text-transform: uppercase;
    font-size: $smaller-font;
    color: var(--foreground-text);
  }
}

.mat-bottom-sheet-container {
  &.ns-errors-display-bottom-sheet {
    background: #2c0000;
    padding: 4px;
    box-shadow: none;

    .errors-list {
      color: rgb(255 255 255 / 80%);
    }
  }
}

.hexa-anim {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~assets/animations/colmeia-flat-icon-anim.svg");
  background-size: 48%;
  background-repeat: no-repeat;
  background-position: 140% 0;
}

.file-detail-search-highlight {
  background: rgb(255 255 0 / 50%);
  border-radius: $border-radius;
}

.tab-button-group-dense {
  mat-button-toggle-group {
    .mat-button-toggle-group {
      padding: 0 10px;
    }
  }
}

.white-box-container {
  &.att-service-pack-style-1 {
    margin: 0;
    gap: 8px;

    .white-box {
      $width: calc(25% - 8px);

      width: $width;
      min-width: $width !important;
      height: unset;
      margin: 0;
      padding: 18px;

      > .content {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.att-service-pack-style-2 {
    @extend .att-service-pack-style-1;

    .white-box {
      $width: calc(33% - 8px);
      width: $width;
      min-width: $width !important;
    }
  }
}

.mat-snack-bar-container.snack-async-proccess-alert {
  padding: 0;
  width: 50vw;
  max-width: 50vw;
  background: none;
}

.dashboard-analytics {
  $width: 94vw;
  $height: 86vh;
  width: $width;
  height: $height;

  .mat-dialog-container {
    overflow: hidden;

    .mat-mdc-dialog-container {
      padding: 10px;
    }

    #embedContainer {
      height: calc($height - 67px);
      border-radius: $border-radius;
      overflow: hidden;
      /* box-shadow: 0 0 0 1px; */
      margin-top: 10px;
    }
  }
}

.denied-files-upload {
  max-width: 720px;
  width: 50vw;
}

app-dashboard-menu {
  .mat-list-base
    .mat-list-item.mat-list-item-with-avatar
    .mat-list-item-content
    .mat-list-text {
    padding: 0 6px;
  }
}

.slc-modal-canon-type-name {
  color: var(--accent-200);
  background-color: var(--primary-600);
  padding: 2px 4px;
  font-weight: bold;
  font-size: 12px;
  margin-left: 4px;
  white-space: nowrap;
  border-radius: $border-radius;
  position: relative;
  top: -1px;
}

.slc-modal-crm-type-name {
  @extend .slc-modal-canon-type-name;
}

.nice-scroll-square-divider {
  @include nice-scroll(
    $size: 4px,
    $border-radius: 0px,
    $background: var(--primary-50),
    $color:
      color.scale(mat.get-color-from-palette($primary, 100), $lightness: 20%),
    $color-hover: var(--primary-200)
  );
}

.nice-scroll-square-divider-8x {
  @include nice-scroll(
    $size: 8px,
    $border-radius: 0px,
    $background: var(--primary-50),
    $color:
      color.scale(mat.get-color-from-palette($primary, 100), $lightness: 20%),
    $color-hover: var(--primary-200)
  );
}

app-module-nav-menus .dashboard-menus-container {
  @extend .nice-scroll-square-divider;
  height: 100%;
  overflow-y: scroll;
}

table {
  &.fixed-table {
    table-layout: fixed;
  }
}

.user-function-alert {
  .app-alert-base {
    padding: 30px;

    .mat-icon {
      margin-right: 16px;
      align-self: flex-start;
    }
  }
}

.title-uppercase {
  text-transform: uppercase;
  font-weight: bold;
  font-size: $smaller-font;
}

ngx-tableau {
  height: 100%;

  .ngx-tableau-viz {
    height: 100% !important;

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.captalize-first-letter {
  &:first-letter {
    text-transform: uppercase;
  }
}

.variables-container.variable-chip-accent-color {
  .mat-chip {
    background-color: var(--accent-300);
    color: var(--accent-300-contrast);
  }
}

cm-date-picker {
  &.no-padding-bottom {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.show-archived-button {
  padding: 0 !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.pressed-button {
  padding: 3px !important;
  background-color: rgba(0, 0, 0, 0.07) !important;
  border-radius: 50px !important;

  mat-icon {
    opacity: 0.8 !important;
  }
}

.ns-picker-dense {
  padding-bottom: 5px !important;

  > .ns-picker > .ns-picker-label {
    padding-bottom: 4px !important;
  }

  mat-chip {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  app-ns-picker-button {
    button {
      width: 26px !important;
      height: 26px !important;

      mat-icon {
        font-size: 18px !important;
      }
    }
  }
}

.hide-toolbar {
  > .ql-container {
    > .ql-editor {
      padding: 0;
      font-size: 16px;
      line-height: 18px;
      overflow-y: hidden;

      :first-child {
        overflow-y: hidden;
      }
    }

    > .ql-tooltip {
      display: none;
    }
  }
}

.ql-mention-list-container {
  border-radius: 4px;
}

.ql-mention-list-item {
  &.selected {
    border-color: transparent !important;
  }
}

input[readonly] {
  cursor: default;
}

.cursor-default {
  cursor: default;
}

app-campaign-action-picker.full-width {
  width: 100%;

  mat-form-field {
    width: 100%;
  }
}

.bpm-mkt-node-dialog {
  min-height: 50vh;
  display: block;
}

app-colmeia-product-list mat-tab-group.vertical-tab {
  gap: 15px;

  mat-tab-header {
    width: 220px;
  }
}

.text-left {
  text-align: left;
}

app-dashboard-active-call-info .customer-info-block-buttons .mat-icon-button {
  width: 30px;
  height: 30px;

  mat-icon {
    font-size: 22px;
    width: 22px;
    height: 24px;
  }
}

.yOpen,
.yDone,
.yRejec,
.yForw,
.yPrcss,
.update,
.create,
.delete {
  .tag-content {
    box-shadow: unset !important;
    border-radius: 50px !important;
    padding-inline: 12px !important;
    font-weight: 500 !important;
  }
}

.yOpen,
.update {
  .tag-content {
    background-color: var(--alert-100);
    color: rgba(var(--alert-default-contrast-rgb), 90%) !important;
  }
}

.yDone,
.create {
  .tag-content {
    background-color: var(--success-200);
    color: rgba(var(--success-default-contrast-rgb), 90%) !important;
  }
}

.yRejec,
.delete {
  .tag-content {
    background-color: var(--warn-100);
    color: var(--warn-900) !important;
  }
}

.yForw {
  .tag-content {
    background-color: var(--info-A200);
    color: var(--info-900) !important;
  }
}

.yPrcss {
  .tag-content {
    background-color: rgb(255, 175, 55);
    color: rgb(110, 66, 0);
  }
}

.clickable-badge {
  position: absolute !important;
  top: -11.8px;
  right: -11.6px;
  width: 23.4px !important;
  height: 23.4px !important;
  border-radius: 50px;
  background-color: var(--warn-200);
  align-items: center;
  justify-content: center;
  display: none;
  transition: 300ms;
  z-index: 1;
  opacity: 1 !important;
  font-size: 14px;

  &.small-badge {
    font-size: 12px;
    width: 17px !important;
    height: 17px !important;
    right: -8.4px;
    top: -8.5px;
  }

  &.on-flat-button {
    width: 23px !important;
    height: 23px !important;
    top: -11.7px;
    right: -19.5px;

    &.small-badge {
      right: -16.5px;
      width: 16.5px !important;
      height: 16.5px !important;
      top: -8.8px;
    }
  }

}

//TOP-BAR-SEARCH
.mh-search-selected-type {
  background-color: var(--accent-100) !important;
}

.highlighted-text-directive {
  background-color: yellow;
  color: inherit;
}

.tag-info {
  background-color: var(--info-A400);
  color: var(--info-900);
}

.tag-ok {
  background-color: var(--success-400);
  color: rgba(var(--success-default-contrast-rgb), 100%);
}

.tag-alert {
  background-color: var(--alert-300);
  color: rgba(var(--alert-default-contrast-rgb), 90%);
}

.tag-error {
  background-color: var(--warn-A200);
  color: #480000;
}

.user-functions-requests-item-details {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: -9px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  max-width: 90vw;

  .details-summary {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .request-response {
    display: flex;
    gap: 16px;

    .request-editor {
      width: 600px;
      max-width: 100%;
      height: 400px;
      max-height: 100%;
    }
  }
}

.user-functions-requests-method,
.user-functions-requests-duration {
  border: 1px solid rgba(var(--primary-default-rgb), 15%);
  display: inline;
  font-size: 0.875rem;
}

.user-functions-requests-status-code,
.user-functions-requests-method,
.user-functions-requests-duration,
.user-functions-requests-date {
  border-radius: 30px;
  width: min-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  height: 20px;
}

.user-functions-requests-pathname {
  font-size: 0.875rem;
  line-height: 1rem;
  max-width: 100%;

  &.in-popover {
    max-width: max-content;
  }
}

.user-functions-requests-fn-name {
  margin: 0;
}

.user-functions-requests-status-code,
.user-functions-requests-method {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0.7rem;
}

.user-functions-requests-date {
  width: max-content;
  font-size: 0.875rem;
  line-height: 1rem;
}

chat-options text-icon-white-box {
  .text-icon-white-box {
    padding: 0 !important;

    hexagon {
      width: auto !important;
      height: auto !important;
    }
  }
}

.var-editor-tiptap {
  > .ProseMirror {
    outline: none;
    height: 100%;
    line-height: 100%;
    overflow-y: auto;
    padding: 8px;
  }
}

.var-editor-content {
  p {
    margin: 0;
  }

  .var-editor-variable {
    background: var(--foreground-surface);
    color: var(--foreground-text);
    position: relative;
    top: -1px;
    font-size: 75%;
    padding: 2px 6px;
    margin: 1px 0;
    min-height: auto;
    font-weight: inherit;
    border-radius: 52px;
  }

  h1 {
    font-size: 2.375rem;
  }

  h2 {
    font-size: 1.375rem;
  }

  ul, ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li {
      list-style: unset;

      p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }
  }

  .editor-image {
    display: block;
    height: auto;
    margin: 1.5rem auto;
    max-width: 95%;

    &.ProseMirror-selectednode {
      outline: 3px solid var(--accent-700);
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid rgba(var(--foreground-color-rgb),.2);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;
    }

    th {
      background-color: rgba(var(--foreground-color-rgb), .1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(var(--foreground-color-rgb), .15);
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--accent-700);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }
}

.enumpicker-action-buttons {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  border-top: 1.5px solid var(--primary-50);
  gap: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.sorting-btn-toggle,
.sorting-btn {
  background-color: white !important;
}

.pagination-sorting-selected {
  color: var(--accent-500);
}

.sorting-option:hover {
  background-color: var(--accent-50) !important;
}

.sorting-selected-option {
  background-color: var(--accent-200);
}

.mat-form-field-fill-border {
  box-shadow: inset 0 0 0 2px rgba(var(--primary-50-rgb), 40%);

  &:hover {
    box-shadow: inset 0 0 0 2px var(--primary-50);
  }
}

.deployed-services-tab-body {
  .mat-tab-body-content {
    padding: 0 4px;
  }
}

app-group-service .bot-history-container app-bbcode-viewer .bbcode__content {
  display: inline;
}

// corrige layout do date picker com horas no mobile
.cdk-overlay-pane.mat-datepicker-dialog.date-picker-has-time-overlay {
  .mat-datepicker-content-touch {
    overflow: auto;

    > .mat-datepicker-content-container {
      height: auto;

      > .mat-datepicker-close-button {
        display: none;
      }
    }
  }
}

.highlighted-text.current-highlight {
  background-color: rgb(255, 153, 0);
}
.blink-highlight {
  animation: blinkFadeInOut 500ms ease-in-out infinite;
}
@keyframes blinkFadeInOut {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgb(255, 153, 0);
  }
  100% {
    background-color: transparent;
  }
}

.dashboard-home-selected-filter {
  background-color: var(--accent-200);
}

.meta-button-login-sdk {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 36px;
  padding: 0 24px;
  position: relative;
  top: 1px; // pequeno fix de alinhamento
}

tickets-view-nav {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  mat-selection-list {
    box-shadow: none !important;

    > mat-list-option {
      height: 54px !important;
      padding: 0 !important;

      &:first-child {
        border-top: none !important;
      }

      .mat-list-item-content {
        padding: 0 26px !important;
      }
    }

    &.none-selected {
      mat-pseudo-checkbox {
        display: none !important;
      }

      .mat-list-text {
        padding-left: 0;
      }
    }
  }
}

.flow-area-card {
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: .5rem;

    h6 {
      font-size: 18px;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .content {
    margin-bottom: .5rem;
  }

  p {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: .5rem 0;
  }

  .count {
    font-weight: bold;
    font-size: 14px;
    background: var(--accent-400);
    display: inline-block;
    text-align: center;
    border-radius: 22px;
    padding: 0px 6px;
    text-transform: uppercase;
  }

  footer {
    margin-top: auto;
  }
}

.window-control-container {
  position: relative;
  z-index: 2;
}

bot-transaction-visualizer .mat-chip {
  align-items: center;
  border-radius: 16px;
  background-color: #e0e0e0;
}

app-callcenter-agents-general-queue-information {
  app-generic-table {
    td {
      padding: 0px !important;

      .insideTd {
        font-size: .75rem;
        white-space: unset !important;
        word-break: normal !important;
      }
    }
  }
}

app-generic-table {
  th {
    font-size: .75rem;
    line-height: normal !important;
    letter-spacing: 0 !important;
  }
}


app-tag-picker-modal {
  .mdc-list-item {
    padding-right: 0px;
    padding-left: 4px;

    .mdc-list-item__content {
      padding-left: 8px;
    }
  }
}

app-ns-picker-selections {
  mat-chip-row {
    .mdc-evolution-chip__text-label {
      div {
        font-size: .75rem !important;
      }
    }
  }
}

app-indexed-search-page {
  .boxed-tabs {
    .mat-mdc-tab {
      flex-grow: 0;
      color: var(--primary-default);
      .mdc-tab__text-label {
        color: red !important;
      }
    }
  }
}

.new-condition-panel {
  mat-expansion-panel-header {
    .mat-content {
      display: flex;
      align-items: center;
    }
  }
}

.dense-radio-button {
  line-height: normal !important;

  .mat-internal-form-field {
    .mdc-radio{
      margin-left: -10px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .mdc-label {
      line-height: normal !important;
    }
  }
}


app-customer-finder {
  .search-box {
    .mat-mdc-radio-button {
      padding-inline: 6px !important;
      .mdc-label {
        padding-left: 0px !important;
      }
    }
  }
}

mat-card {
  .card-header-with-tag {
    .mat-mdc-card-header-text {
      width: 100% !important;
    }
  }
}

app-ns-picker-modal-selectables {
  .mat-mdc-radio-button {
    .mdc-label {
      padding-left: 0;
    }
  }
}

.dashboard-page {
  .mat-mdc-card {
    > :first-child {
      margin-top: 0;
    }
  }
}

app-canonical-picker-edit-canonical-config {
  .mdc-label {
    padding-left: 0 !important;
  }
}

app-create-edit-engagement-dialog {
  .target-select {
    .mat-mdc-form-field-hint-wrapper {
      padding-inline: 12px !important;
    }
  }
}

//Fixes all interaction prompts
app-interactive-prompt {
  app-bbcode-viewer div.bbcode__content.ng-star-inserted {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--font-family);
    letter-spacing: normal;
    color: rgba(var(--foreground-color-rgb), .9);
  }
}

.custom-bot-action-select-panel {
  font-size: .875rem !important;
  min-width: 260px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .mdc-list-item {
    font-size: .875rem !important;
    min-height: 42px !important;
  }
}

app-dashboard-security-control-edit {
  mat-checkbox {
    &.dense-checkbox {
      .mat-internal-form-field {
        height: 20px;
      }
    }
  }
}


selected-tags-list-for-picker {
  .mat-mdc-chip-focus-overlay,
  .mat-ripple {
    display: none;
  }
  .mat-mdc-chip-row,
  .mdc-evolution-chip__action,
  .mdc-evolution-chip__text-label,
  .mat-mdc-chip-primary-focus-indicator,
  .mat-mdc-chip-focus-overlay {
    cursor: default;
  }
}

app-ns-picker-selections {
  .mdc-evolution-chip-set__chips {
    row-gap: 4px;
  }
}

app-dashboard-file-detail {
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 1 !important;

    &.mdc-tab-indicator--active {
      color: var(--primary-400-contrast) !important;
      .mdc-tab__text-label {
        color: var(--primary-400-contrast) !important;
      }
    }
    .mdc-tab__text-label {
      color: rgba(255,255,255,.65) !important;
    }

    .mdc-tab-indicator__content  {
      display: none;
    }
  }
}

ticket-status-button {
  .mat-mdc-menu-trigger {
    .mdc-button__label {
      width: 100%;
    }
  }
}

callcenter-agent-card {
  .mat-mdc-chip-listbox {
    .mat-mdc-chip-option {
      .mdc-evolution-chip__text-label {
        display: flex;
        align-items: center;
      }
    }
  }
}

app-dashboard-knowledge-base-info,
app-kb-strategies-page {
  .strategies-table {
    .mat-mdc-row {
      vertical-align: bottom;
      height: 76px;

      .mat-mdc-cell {
        padding-bottom: 8px;
      }

      .mat-mdc-slider {
        position: relative;
        bottom: -14px;
      }
      .icon--row {
        position: relative;
        bottom: -8px;
      }
    }
  }
}

.expandend-table-cell {
  height: 76px !important;
  min-height: 76px !important;
  padding-bottom: 8px;

  .mat-mdc-slider {
    position: relative;
    bottom: -24px;
  }
  .icon--row {
    position: relative;
    bottom: -8px;
  }
}

.button-alert-container {
  position: relative;
  right: 0;
  display: inline-block; /* Ensure it only takes up as much space as the button */
  margin-top: $gutter;

  // Corrects the alert on this case
  button-alert {
    right: unset;
    width: 100%;
    z-index: 10;
  }
}


.simple-counter-tag {
  font-size: 12px;
  color: var(--foreground-secondary-text);
  background: rgba(var(--foreground-color-rgb), .06);
  border-radius: var(--border-radius);
  font-weight: bold;
  padding: 2px 6px;
}

app-changed-elements-dialog,
app-changed-elements-without-version-dialog,
.versioning-elements-container {
  display: block;
  max-height: 100%;
  height: 100%;
}


// div {
//   &.boxed {
//     height: initial;
//     border: 1px solid rgba(0, 30, 30, 0.4);
//     padding-left: 12px;
//     padding-right: 6px;
//     border-radius: 4px;

//     &:hover {
//       border-color: rgba(0, 30, 30, 0.9);

//     }
//   }


// }

.whatsapp-preview-lit {
  margin-left: 26px;
}

.list-style-disc {
  margin-top: 0;
  margin-bottom: 0;
  li {
    list-style: disc !important;
  }
}

.list-style-decimal {
  margin-top: 0;
  margin-bottom: 0;
  li {
    list-style: decimal !important;
  }
}

tickets-view-card-dialog {
  .mat-mdc-tab-group {
    display: flex;
    flex-direction: column;

    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;

      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

app-chat-action-bar .ep-emojies {
  background-color: $white;
}

app-main-header-view .mat-drawer-inner-container {
  // fixes main-header-view's curved-corner
  overflow: visible;
}
