@use '@angular/material' as mat;

.boxed-table {
  border-spacing: 0;

  td {
    padding-left: 10px;
    padding-right: 10px;
  }

  $background-header-color: rgba(var(--foreground-divider-rgb), 2%);
  $border-color: rgba(var(--foreground-divider-rgb), 6%);

	thead {
		background-color: $background-header-color !important;
    font-weight: bold;
    border-spacing: 0;

    tr {
      height: 46px;
    }

    th, td {
      color: rgba(var(--foreground-text-rgb), 80%);
      border-color: $border-color;
      border-bottom-width: 2px;
      box-shadow: inset 0 2px 0 0 $border-color;

      &.control-column {
        width: 20%;

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:first-child {
        box-shadow: inset 2px 2px 0 0 $border-color;
      }

      &:last-child {
        box-shadow: inset -2px 2px 0 0 $border-color;
      }

      &:first-child:last-child {
        box-shadow:
          inset 0 2px 0 0px $border-color,
          inset 2px 0px 0 0px $border-color,
          inset -2px 0px 0 0px $border-color
        ;
      }
    }

    &:first-child {
      th, td {
        &:first-child {
          border-radius: $border-radius 0 0 0;
        }

        &:last-child {
          border-radius: 0 $border-radius 0 0;
        }

        &:first-child:last-child {
          border-radius: $border-radius $border-radius 0 0;
        }
      }
    }
	}

  tbody, tfoot {
    tr {
      $color: rgba(var(--foreground-divider-rgb), 8%);

      td {
        border-bottom: 1px solid $color;

        &:first-child {
          box-shadow: inset 2px 0 1px $color;
        }

        &:last-child {
          box-shadow: inset -2px 0 1px $color;
        }

        &:first-child:last-child {
          box-shadow:
            inset 2px 0 0 0 $color,
            inset -2px 0 0 0 $color,
          ;
        }

        &.control-column {
          width: 20%;

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  &:not(.boxed-table-with-footer) tbody tr:last-child td {
    $color: rgba(var(--foreground-divider-rgb), 8%);
    box-shadow: inset 0 -1px 1px $color;

    &:first-child {
      border-radius: 0 0 0 $border-radius;
      box-shadow: inset 2px -1px 1px $color;
    }

    &:last-child {
      border-radius: 0 0 $border-radius 0;
      box-shadow: inset -2px -1px 1px $color;
    }

    &:first-child:last-child {
      border-radius: 0 0 $border-radius $border-radius;
      box-shadow:
        inset 2px -1px 0 0 $color,
        inset -2px 0 0 0 $color,
      ;
    }
  }

  tfoot tr:last-child td {
    $color: rgba(var(--foreground-divider-rgb), 8%);
    box-shadow: inset 0 -1px 1px $color;

    &:first-child {
      border-radius: 0 0 0 $border-radius;
      box-shadow: inset 2px -1px 1px $color;
    }

    &:last-child {
      border-radius: 0 0 $border-radius 0;
      box-shadow: inset -2px -1px 1px $color;
    }

    &:first-child:last-child {
      border-radius: 0 0 $border-radius $border-radius;
      box-shadow:
        inset 2px -1px 0 0 $color,
        inset -2px 0 0 0 $color,
      ;
    }
  }

  &.mat-primary {
    thead {
      background-color: var(--primary-500);
      font-weight: bold;

      th, td {
        color: var(--primary-default-contrast);
      }
    }
  }
}
