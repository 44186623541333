app-crm-service-tickets-view {
  background-color: var(--background);

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 12px 0;
    gap: 8px;

    >.left,
    >.right {
      display: flex;
      align-items: center;
      position: sticky;
    }

  }

  .header-container .left {
    gap: 4px;
  }

  .header-container .right {
    gap: 4px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .package-selector {
    width: fit-content;

    >mat-select {
      padding: 10px 18px;
      border-radius: var(--border-radius);
      background: rgba(var(--foreground-divider-rgb), 4%);
      font-weight: bold;
      height: auto;
      line-height: normal;

      &:hover {
        background: rgba(var(--foreground-divider-rgb), 12%);
      }

      .mat-mdc-select-value {
        line-height: normal;
      }
      .mat-mdc-select-arrow-wrapper {
        height: 19px;
      }
    }


  }

  app-kanban-viewer {
    // padding: 0 30px;

    >div {
      width: 100%;
      height: 100%;
      overflow: auto;

      // > .kanban-viewer {
      //   width: 100%;
      //   height: 100%;

      // }
    }
  }

}

.filter-container {
  .filter-button {
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.crm-counter {
  border-radius: var(--border-radius-2x);
  padding: 0.1rem 0.4rem;
  font-weight: bold;
  background: rgba(var(--accent-default-rgb), 20%);
  font-size: small;
}

.crm-project-key {
  border-radius: var(--border-radius-2x);
  padding: 0.1rem 0.4rem;
  font-weight: bold;
  background: rgba(var(--primary-default-rgb), 20%);
  font-size: small;
}

.mat-mdc-menu-item.gs-ticket-menu-btn {
  line-height: 34px;
  height: 34px;
  min-height: 34px;

  .content {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: var(--border-radius);
    padding: 0 12px;
    margin: 0 -12px;
  }

  &.active .content {
    background: rgba(var(--accent-default-rgb), 12%);
    color: var(--accent-default);
    font-weight: bold;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}


.ticket-page {
  #dashboard-view-content {
    overflow: hidden;

    app-empty-view {
      height: 100%;
    }

    app-crm-service-tickets-view {

      .tickets-view-container > .header {
        padding: 10px;
      }

      app-kanban-viewer {

        >div {
          padding: 0 10px;
        }
      }

    }
  }
}

.custom-select-panel {
  min-width: 220px !important;
}

// .gs-ticket-active-dot {
//   width: 10px;
//   height: 10px;
//   border-radius: 100%;
//   background-color: var(--primary);
// }



.mat-mdc-menu-item.ticket-attr-drop-down {
  --mat-menu-item-label-text-line-height: 12px;
  --mat-menu-item-spacing: 0;
  
  &.l-selected {
    box-shadow: inset 2px 0px 0px 0px var(--primary-default);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
  }
}