@use '@angular/material' as mat;
@import 'lib';

app-dashboard-sn-users-list,
app-dashboard-sn-user-details {

  .list-item-column.pick-column {
    width: 50px !important;
  }
  
  .search-input-wrapper {
    margin-left: 0 !important;
  }
  
  .fixed-callbacks {
    gap: 12px;
  }
  
  .list-container {
    margin-top: 8px;
  }

  .resfresh__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .remove-selections-button {
    margin-left: 8px;
  
    .count {
      font-size: $smaller-font;
      background-color: rgba(var(--warn-default-contrast-rgb), 24%);
      border-radius: 100%;
      width: 22px;
      height: 22px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .refresh-list__btn {

    // &.loading {
    //   animation: spin .7s infinite linear;
    // }
    // @keyframes spin {
    //   0% {
    //     transform: rotate(0deg);
    //   }
    //   100% {
    //     transform: rotate(360deg);
    //   }
    // }
  }
  
  .list-checkbox {
    --accessibility-padding: 16px;
  }
  
  .list-hexagon {
    margin: 0 6px !important;
    @include mq('sm') {
      margin: 0 14px !important;
    }
  }
  
  .simple-input::-webkit-search-cancel-button {
    display: none;
  }
}


app-dashboard-sn-users-list {
  .pagination {
    display: flex;
    align-items: center;
  
    .items-counter,
    .arrow-controls {
      margin-left: 20px;
    }
  
  
    .arrow-controls {
      display: flex;
    }
  
    .perpage-controls {
      display: flex;
    }
  
    .paginator-arrow-button {
      display: flex;
      margin-left: 5px;
      align-items: center;
    }
  
    .pg-disabled {
      opacity: 0.4;
      cursor: default;
    }
  
  }

}

app-dashboard-sn-user-details {
  .box-title {
    padding: 18px;
  }

  .remove-selections-button {
    margin: 0;
  }
}
