@use '@angular/material' as mat;
@use 'sass:color';
@import "lib";


$row-button-color: var(--accent-50-contrast);
$row-button-bg-color: var(--accent-50);

.generic-dash-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1.5rem;

  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }

  .generic-dashboard-card {
    height: 100%;
  }
}

.generic-dashboard-card {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--foreground-divider);
  border-radius: $border-radius;
  padding: 20px;

  .info-row {
    h5 {
      color: var(--foreground-secondary-text);
      font-size: 0.75rem;
      font-weight: normal;
      margin: 0 0 .25rem 0;
    }

    p {
      margin: 0;
    }
  }

  > header {
    display: flex;
    gap: 20px;

    .avatar {
      flex-shrink: 0;
      position: relative;
      height: 70px;
      width: 70px;

      hexagon {
        height: inherit;
        width: inherit;
      }

      .status {
        position: absolute;
        bottom: 2px;
        right: 2px;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header-info {
      position: relative;
      flex-grow: 1;

      h4 {
        margin: 0;
        line-height: 1;
      }

      .side-info {
        font-size: .8rem;
        margin-left: auto;
        line-height: 1;
      }

      .main-info {
        display: flex;
        align-items: flex-end;
        padding-bottom: .5rem;
        border-bottom: 1px solid var(--foreground-divider);
        margin-bottom: .5rem;
      }

      .secondary-info {
        display: flex;
        align-items: flex-start;

        .side-info {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  > .main-content {
    gap: 1rem;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  > footer {
    margin-top: auto;
  }
}
