@use '@angular/material' as mat;
@import 'vendors/material/theme_variables';

.cond-accordion-panel {
  > mat-expansion-panel-header {
    padding-left: 8px;
    height: 68px;
    font-size: 18px;

    .mat-content {
      overflow: visible;
    }

    &[aria-disabled=true] {
      padding-right: 32px;
      color: var(--foreground-text);
    }

    mat-slide-toggle {
      font-size: 1rem;
    }
  }

  mat-panel-title {
    display: flex;
    align-items: center;
  }

  mat-panel-description {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .drag-handle, .cond-type-icon {
    margin-right: 8px;
    box-sizing: content-box;
  }

  .cond-type-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top: 6px;
    color: var(--primary-default);
  }

  .drag-handle {
    cursor: move;
    padding: 4px;
    transition: opacity .2s ease-in-out;

    &:not(:hover) {
      opacity: 0.8;
    }
  }

  .cond-type-icon {
    @include mat-icon-size(16px);
  }
}
