// ======================================
//  Base Styles
// ======================================

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  // border: 1px solid red;
}

html, body {
  font-family: var(--font-family);
  font-size: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  overscroll-behavior-y: contain;
}

// this class compensates main header position fixed
.ptop {
  padding-top: $main-header-height;
}

li {
  list-style: none;
}

h1 {
  font-size: $fs-xl;
}

h2 {
  font-size: $fs-lg;
  font-weight: 500;
}

h3 {
  font-size: $fs-md;
  font-weight: 500;
}

h4 {
  font-size: $fs-sm;
  font-weight: 500;
}

h5 {
  font-size: inherit;
  font-weight: 500;
}

mat-sidenav {
  max-width: 50vw !important;
}

// .mat-drawer-inner-container {
//   height: unset !important;
//   // padding: 30px 0;
// }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.group-list-container {
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}

.groupnav.header-side-bar {
 .mat-drawer-inner-container {
   display: flex;
   flex-direction: column;
 }
}
