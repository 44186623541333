@use '@angular/material' as mat;
@use 'sass:color';
@import "lib";

$row-button-color: var(--accent-50-contrast);
$row-button-bg-color: var(--accent-50);

cm-generic-dashboard-home,
.generic-dashboard-home {
  :host {
    display: block;
  }

  h3 .helper-info {
    margin-left: 5px;
  }

  .top-controls {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    &>.top-filters {
      display: flex;
      padding: 6px 0;

      &>.ordenable-items {
        margin-bottom: -20px;
        margin-right: 12px;

        .filter-direction-icon {
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform .2s ease-in-out;
          box-sizing: content-box;

          &.rotate {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }


  .fixed-callbacks {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-bottom: 2px;
      text-transform: capitalize;
    }

    &>.generic-dashboard-button {
      margin-left: 8px;

      &.button-edit {
        min-width: initial;
      }
    }
  }

  .list-item-column.pick-column {
    align-items: center;
    width: 20%;
    --accessibility-padding: 16px;
  }

  @include mq("md") {
    .list-item-column.pick-column {
      width: 30%;
      justify-content: center;

      mat-checkbox {
        margin-left: -10px;
      }
    }
  }

  .list-item-column--expanded {
    @include mq('md') {
      width: 200% !important;
    }
  }

  .labels-container {
    display: flex;

    &>.label {
      margin-right: 8px;
      padding: 2px 10px;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .labels-dropdown-button {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;

    .labels-dropdown-button-label-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .labels-dropdown-button-texts-wrapper {
        display: flex;
        align-items: center;

        text-overflow: ellipsis;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .search {
    &__container {
      display: flex;
    }

    &__item {
      flex: 1;
    }

    &__icon {
      cursor: pointer;
    }
  }

  .multiple-controls {
    padding: 8px 0;
    padding-right: 12px;
    display: flex;
    gap: 4px;
  }

  .loading-spinner-container {
    margin: 12px;

    >mat-spinner {
      display: block;
      margin: 0 auto;
    }
  }

  .error-alert {
    .error-alert-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .l-title {
        margin-top: 0;
        margin-bottom: 0.6rem;
      }
    }
  }

  .box-style-2 {
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
  }

  app-tag-pick-filter-for-list {
    display: block;
    position: relative;
    z-index: 2;
    // background: #fff;
    margin-right: -4px;
  }

  .list-item-column {

    .label-placeholder,
    .content-placeholder {
      width: 180px;
      height: 4px;
      border-radius: $border-radius;
      display: inline-block;
      background-color: rgba(var(--foreground-secondary-text-rgb), 50%);
    }

    .content-placeholder {
      width: 320px;
    }
  }

  .top-actions,
  .bottom-actions {
    position: sticky;
    z-index: 2;
  }

  .top-actions {
    top: 14px;

    @include mq("sm+1") {
      top: -30px;
    }

    @include mq('sm', true) {
      padding-top: 20px;
      padding-bottom: 2px;
      margin-top: -20px;
      margin-bottom: 2px;
      top: -20px;
      background-color: white;
      // box-shadow: 0px 10px 10px -9px rgba(0,0,0,.15);
    }
  }

  .bottom-actions {
    bottom: -30px;
  }

  .generic-dashboard-button {
    text-transform: capitalize;
  }

  app-alert:not(.no-margin-bottom) {
    margin-bottom: 24px;
  }


  @include mq('sm', true) {
    .multiple-controls {
      padding-inline: 4px;
    }

    .box-style-2 {
      margin-bottom: 2px !important;
      padding-inline: 4px;

      &.pagination {
        justify-content: center;
      }
    }
  }
}
.selected-filter {
  background-color: var(--accent-50);
}

.list-item {
  .async-remove-container {
    position: absolute;
    display: flex;
    gap: 8px;
    align-items: center;
    left: 8px;
    transform: translateY(-50%);
    background: var(--alert-default);
    color: var(--alert-default-contrast);
    border-radius: var(--border-radius);
    top: 50%;
    width: 80%;
    line-height: 100%;
    z-index: 3;
    padding: 12px 14px;
  }
}

.list-item.marked-to-delete {
  cursor: wait;
  position: relative;

  > .list-item-actions-column,
  > .list-item-column {
    animation: list-loading 1s linear infinite reverse;
    pointer-events: none;

    &:nth-child(1) {
      animation-duration: 1s;
    }
    &:nth-child(2) {
      animation-duration: 1.2s;
    }
    &:nth-child(3) {
      animation-duration: 1.4s;
    }
    &:nth-child(4) {
      animation-duration: 1.6s;
    }
    &:nth-child(5) {
      animation-duration: 1.8s;
    }
    &:nth-child(6) {
      animation-duration: 2s;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(var(--alert-default-rgb), 84%);
    z-index: 2;
    left: 0;
    animation: list-loading 2s linear infinite reverse;
    top: 0;
  }
}
