app-var-editor-text-area {
  .text-area-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--foreground-divider);
    border-radius: var(--border-radius);

    .l-left-tools,
    .l-right-tools {
      display: flex;
      align-items: center;
    }

    .toggle-emoji,
    .toggle-raw {
      padding: 0px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius);

      &.active {
        box-shadow: none;
        background-color: var(--foreground-divider);
      }
    }

    .toggle-emoji {
      align-self: stretch;
    }

    .font-size-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0px 6px;
      > .font-size-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
      }
    }
  }

  .text-length-count {
    font-weight: bold;
    font-size: 0.8rem;
    margin-right: 18px;
    display: flex;
    gap: 4px;
    align-items: center;

    .chars-count {
      border-radius: 52px;
      padding: 3px 24px 3px 8px;
      background: rgba(var(--primary-50-rgb), 0.4);
      line-height: 100%;
      display: inline-flex;
      align-items: center;
      margin-right: 24px;
      gap: 2px;
    }
  }
}
