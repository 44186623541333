@use '@angular/material' as mat;
@import 'lib';

app-conditions-editor,
app-condition-edit {

  .create-edit-container {
    display: flex;
  }

  app-ns-picker {
    display: inline-block;
    margin: 0;
  }

  .actions-container {
    display: flex;

    & > button {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  .conditions-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .wrapper {
      width: 100%;
    }


    .condition-list-item {
      position: relative;
      width: 100%;
      flex-direction: column;
      display: flex;
      justify-content: space-around;
      padding: 12px;
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid rgba(var(--foreground-divider-rgb), 16%);

      &:not(:last-child) {
        margin-bottom: 12px;
      }


      .condition-container {
        position: relative;
        display: flex;

        $remove-button-width: 40px;

        .l-header {
          width: calc(100% - $remove-button-width);
          display: flex;
          gap: 6px;
          position: absolute;
          bottom: 100%;
          opacity: 0;
          transition: .4s cubic-bezier(0.58, 0.04, 0.24, 0.99);
          transform: translateY(4px);

          > div {
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
            text-transform: uppercase;

            > span {
              position: relative;
              padding: 2px 6px;

              &:before {
                content: "";
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                z-index: -1;
                backdrop-filter: blur(16px);
              }
            }
          }

          > .h-value { width: 40%; }
          > .h-comp { width: 60%; }
        }

        &:hover {
          .l-header {
            transform: translateY(-6px);
            opacity: 1;
          }
        }

        > .condition-content {
          width: 100%;
        }

        .content-inputs {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 6px;

          @include mq('sm2', $max: true) {
            flex-direction: column;
            align-items: initial;
          }
        }

        .config-section {
          display: flex;
          align-items: center;
          width: 60%;
          gap:6px;
          flex-wrap: wrap;

          &.source-config {
            width: 40%;
            align-items: center;
          }
        }

        .select-mode,
        .info-selector {
          border-radius: 34px;
          overflow: hidden;
        }

        .source-type-selector,
        .type-selector,
        .target-selector {
          height: 34px;
          min-height: 34px;

          .target-select-label {
            display: flex;
            align-items: center;
          }
        }

        .type-selector {
          min-width: 140px;
          padding-right: 0px;
          padding-left: 0px;

          .text-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-inline: 6px;
            width: 100%;
          }

          .text {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .source-type-selector {
          // width: 142px;

          .source-type-selector-label {
            display: flex;
            align-items: center;
          }
          .label-content {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        // .target-container {
        //   width: 100%;

        //   > div {
        //     width: 100%;
        //   }
        // }

        .condition-type {
          font-size: 12px;
          padding: 4px 8px;
          border-radius: 4px;
          background-color: #ccc;
          display: inline-block;
          margin-bottom: 4px;
        }

        .condition-label,
        .condition-value-input {
          display: inline-block;
        }

        .condition-label {
          font-size: 1.4rem;
        }

        .condition-value-input {
          width: 200px;
          height: 36px;
          font-size: 1.2rem;
          margin: 0 8px;
          margin-right: 6px;
          display: inline;
          border-radius: 0 $border-radius $border-radius 0;
          border: 2px solid var(--primary-default);
          color: var(--primary-default);
          margin-left: 0;
          background: rgba(var(--primary-default-rgb), 10%);
          padding: 0 12px;
        }

        .top-controls,
        .bottom-controls {
          display: flex;
          align-items: center;
        }

        .top-controls {
          margin-bottom: 12px;
        }

        .bottom-controls {
          margin-top: 6px;

          > .reverse-condition {
            margin-right: 8px;
          }
        }

        .condition-value-container {
          display: flex;
          align-items: flex-end;
        }
      }

      .actions-container {
        justify-content: flex-end;
        margin-left: auto;
        align-items: center;

        & > button {
          font-size: 12px;
          line-height: 28px;

          mat-icon {
            @include mat-icon-size(24px);
          }
        }
      }

      &:before {
        position: absolute;
        top: 50%;
        right: -14px;
        margin-top: -14px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: 'Material Icons';
        content: 'priority_high';
        white-space: nowrap;
        word-wrap: normal;
        font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        background-color: var(--alert-default);
        color: var(--alert-default-contrast);
        opacity: 0;
        transform: translateX(-10px);
      }

      &.invalid {
        &:before {
          opacity: 1;
          transform: translateX(0);
        }

        background-color: rgba(var(--alert-default-rgb), 18%);
        border-color: var(--alert-default);
      }
    }
  }


  .type-list-selector {
    .type-list-content {
      position: relative;
      max-width: 200px;
      overflow: hidden;

      &:before {
        content: "";
        width: 10%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, transparent, var(--primary-default) 90%);
      }

      > .type-list-item {
        padding: 4px 8px;
        font-size: 12px;

        &:not(:first-child) {
          border-left: 1px solid rgba(var(--primary-default-contrast-rgb), 14%);
        }
      }
    }
  }


  .type-list-item {
    &.date-validation {
      padding: 8px 8px 0 8px;
    }
  }

  .date-element-trigger {
    line-height: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .date-validation-field {
    width: 100%;
  }

  .date-op-selector {
    margin-right: 6px;
  }

  .form-field {
    margin-bottom: -1.36em;
  }


  .remove-button {
    min-width: initial;
    padding: 0 8px;
    width: 40px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dynamic-handler-placeholder {
    width: 90px;
    height: 34px;
    background-color: rgba(var(--primary-default-rgb), 10%);
    border-radius: var(--border-radius);
    animation: blinking 0.4s linear infinite alternate;
  }

  .source-label {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 6px;
  }
}
