@use '@angular/material' as mat;

$border: 1px solid rgba(var(--foreground-divider-rgb), 18%);
$right-degrade: linear-gradient(90deg, transparent , white);
$left-degrade: linear-gradient(90deg, white , transparent);

.modal-w-sidebar-container {
  display: flex;
  height: 100%;

  .sidebar {
    width: 340px;
    border-right: $border;
    background: $right-degrade;

    &.invert-degrade {
      background: $left-degrade;
    }

    &.no-border {
      border: none;
    }

    & > .content {
      padding: 1rem;
    }
  }

  .main + .sidebar {
    border-right: 0;
    background: $left-degrade;
    
    &:not(.no-border) {
      border-left: $border;
    }

    &.invert-degrade {
      background: $right-degrade;
    }
  }

  .main {
    width: calc(100% - 340px);
  }
}