@use '@angular/material' as mat;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

.mat-card-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: #fff;
    color: var(--foreground-text);
    box-shadow: 0 0 0 1px var(--foreground-divider);
    border-radius: 50%;
    width: 36px;
    height: 36px;

    &:after {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .swiper-button-prev {
    &:after {
      position: relative;
      left: -2px;
    }
  }
}

.menu-container-swiper {

  .swiper-pagination {
    bottom: -20px;
  }

  &.has-answer {
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      display: none;
    }
  }

  .swiper-slide {
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    transform: scale(0.96);
    opacity: 0.6;

    .carrousel-item {
      background: var(--primary-A700) !important;
      color: var(--primary-default-contrast) !important;
      box-shadow: 0 0 0 3px var(--accent-default);

      .option-title {
        &:before {
          background: var(--accent-default) !important;
        }
      }
    }

    &.swiper-slide-active {
      transform: scale(1);
      opacity: 1;

    }
  }

  .swiper-button-prev {
    left: -8px;
  }

  .swiper-button-next {
    right: -8px;
  }

}
