// **********************************************
// 
//                  TEXTAREA
// 
// **********************************************
textarea{
    
  &:focus{
    outline: none
  }
  &::-webkit-scrollbar {
    width: 20px;
    height: 18px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 1px 0px 1px -1px rgba(255, 255, 255, 0.45), inset -1px 0px 1px -1px rgba(255, 255, 255, 0.45);
    border-radius: 0;
    margin: 10px
  }
   
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 9px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(99, 100, 104, 1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
      background-color: transparent;
  }
}

// **********************************************
// 
//                  HELPER CLASSES
// 
// **********************************************

// When the background is black we need to show a white font color
.interaction__text--white-text {
  color: $white;
}
.invalid::-webkit-input
.invalid{
  border: 1px solid get-item('colors', 'error');
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: get-item('colors', 'error');
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: get-item('colors', 'error');
    opacity: 1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: get-item('colors', 'error');
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: get-item('colors', 'error');
    opacity: 1;
  }
}
