// ======================================
//  Button Styles
// ======================================
@import 'utilities/variables';

%btn {
  color: $grey;
  @include flexed($just: center, $align: center);
  border: get-item('borders', 'button');
  border-radius: get-item('bradius', 'button');
  background-color: get-item('colors', 'button-bg');
  box-shadow: $shadow-terciary;

  &:hover {
    opacity: get-item('opacity', 'hovered');
  }

  i.material-icons {
    margin-right: 2px;
  }
}

.btn {
  // size
  &--sm {
    @extend %btn;
    width: $btn-width-sm;
    height: $btn-height-sm;
  }
  &--md {
    @extend %btn;
    width: $btn-width-md;
    height: $btn-height-md;
    font-size: get-item('fsize', 'subtitle');
  }
  &--lg {
    @extend %btn;
    width: 240px;
    height: 60px;
    font-size: get-item('fsize', 'subsection');
  }
  &--full {
    @extend %btn;
    height: 60px;
    font-size: get-item('fsize', 'subtitle');
  }

  // colors
  &--grey {
    color: $white;
    background: $mid-grey;
  }

  &--white {
    color: $grey;
    background: $white;
  }

  &--blue {
    background-color: $blue;
    color: $white;
  }

  &--flexible-size {
    height: unset;
    opacity: unset;
    box-shadow: unset;
    padding: 6px;
  }

}

button {
  color: inherit;
  background: none;
  outline: transparent;
  border: none;
  cursor: pointer;
}

.disabled {
  color: $white;
  background: $mid-grey;
  cursor: not-allowed;
  pointer-events: all !important;
}
