//-------------------------------*/
//           MODAL STYLES
// -------------------------------*/

$modalWidthDesktop: 500px;

// The black bg that appears behind a modal
.modal-background {
    position: fixed;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: $dark-grey-transparent;
    z-index: $zIndexLayer7;
    top: 0;
    left: 0;
    padding: 0;
}


// The modal component itself
modal {
    /* modals are hidden by default */
    display: none;

    .modal__body {
      @include flexed($just: center, $align: center);
    }

    // Modal white part taht covers the bg
    .modal__content {
        display: block;
        position: fixed;
        padding: 20px 15px;
        opacity: 1;
        z-index: $zIndexPopup;
        background: #fff;
        margin: 15px 0;
        border-radius: 20px;
        box-shadow: 1px 0px 6px rgba(0,0,0,0.5);
        width: 90%;
        max-height: 96vh;
        max-width: $max-width;

        /* enables scrolling for tall modals */
        overflow: auto;

        // Modal transition
        transition: all .6s ease;
        animation: modal-enter 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

        // Modal header with close button
        .modal-body__header {
          @include flexed($just: space-between);

          &--title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: get-item('fsize', 'subtitle');
            font-weight: bold;
          }
          &--close-button {
              width: 15px;
              height: 15px;
              padding: 0;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: right top;
          }
        }

        .modal-body__separator {
          margin-top: $gutter;
          margin-bottom: $gutter;
        }

        // Everything inside the modal
        .modal-body__content {
          max-height: 80vh;
          overflow: auto;
          h2 {
              color: #58595e;
              font-size: 30px;
          }
          hr {
              color: #dedede;
              border-width: 4px;
              margin: 5px 0;
          }
          h4 {
              color: #58595e;
              font-weight: bold;
          }
        }
    }

    // Input of multimedia uploader
    .multimedia-uploader {
        input {
            display: none;
        }
    }
}

body.modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}

// Modal animation when clicked
@keyframes modal-enter {
    0% {
      transform:translateY(-30%);
      visibility: hidden;
      opacity: 0;
    }
    100% {
      transform:translateY(0);
      visibility: visible;
      opacity:1;
    }
}
