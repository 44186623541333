

app-non-serializable-taggable-editor.window-mode {

  app-tag-picker {
    > button {
      background-color: rgb(228 233 245);
    }
  }

  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: 50%;
    right: -14px;
    transform: translateY(-50%);
    box-shadow: 0 0 0 4px rgb(228 233 245);
  }
}

.cdk-overlay-pane.dialog-window.dragging {
  .mat-dialog-container {
    overflow: visible;
  }

  .dashbroad-move-window-trigger {
    width: 300vw;
    height: 300vh;
  }
}
