@use '@angular/material' as mat;

$border-radius-kanban: 8px;

:root {
  --kanban-gutter: 12px;
}

.kanban-column {
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.kanban-columns-header,
.kanban-columns-body {
  display: flex;
  gap: var(--kanban-gutter);
}

.kanban-columns-header {
  position: sticky;
  top: 0;
  z-index: 10;
  
  .kanban-column {
    border-radius: 0;
    background-color: white;
    position: relative;
    overflow: visible;
    
    &.disabled-invalid-drop {
      &:before {
        content: var(--kanban-disabled-message);
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: auto;
        padding: 10px;
        background: linear-gradient(0deg, transparent, var(--alert-A400) 30%);
        color: var(--alert-text);
        font-weight: bold;
        padding: 1rem 1rem 2rem 1rem;
        white-space: pre-wrap;
        text-align: center;
      }
    }

    h4 {
      color: rgba(var(--foreground-text-rgb), 70%);
      font-size: 16px;
      font-weight: bold;
      
      padding: 16px 16px 16px 18px;
      background-color: rgba(var(--foreground-divider-rgb), 4%);
      border-radius: var(--border-radius-2x) var(--border-radius-2x) 0 0;
      margin: 0;

      .count {
        display: inline-block;
        font-size: 13px;
        line-height: 20px;
        padding: 0 7px;
        background-color: rgba(var(--foreground-divider-rgb), 10%);
        border-radius: 10px;
        margin-left: 8px;
        text-align: center;
      }
    }
  }
}

.kanban-columns-body {
  min-height: 620px;
  padding: 3px 0;
    
  .kanban-column {
    border-radius: 0 0 var(--border-radius-2x) var(--border-radius-2x);

    &.dragging-source-column { 
      .kanban-card-list {
        background: rgba(var(--accent-default-rgb), 10%);
      }
    }

    &.disabled-invalid-drop {
      .kanban-card-list {
        background-color: var(--alert-A400);
        color: var(--alert-text);
      }
    }

    .kanban-card-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      min-height: 100px;
      
      padding: var(--kanban-gutter);
      border-radius: 0 0 $border-radius-kanban $border-radius-kanban;
      background-color: rgba(var(--foreground-divider-rgb), 4%);

      transition: background-color 0.2s ease;
    }

    &:has(.kanban-card-list.is-dragging-over) {
      box-shadow: inset 0 0 2px 2px rgba(var(--accent-default-rgb), 100%);
      // outline: solid 2px ;
    }
  }
}


.kanban-card-wrapper {

  &.is-dragging .kanban-card {
    transform: var(--rotate) translateY(20px);
    box-shadow: 
      0px 0px 2px 0px rgba(0, 0, 0, 0.05),
      0px 18px 42px -12px rgba(0, 0, 0, 0.4)
    ;

    &::before {
      opacity: 1;
    }
  }

  &.is-drop-animating .kanban-card {
    transform: rotate(0);
  }

  &.is-loading {
    pointer-events: none;

    .kanban-card {
      outline: 2px solid var(--accent-200);
      animation: blinking 0.4s linear infinite alternate;
    }

  }
  
}

.kanban-card {
  position: relative;
  border-radius: $border-radius-kanban;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px -4px rgba(0, 0, 0, 0.1);
  transition: background-color 200ms, transform 200ms;
  cursor: pointer;
  margin-bottom: var(--kanban-gutter);

  &:hover {
    background-color: rgba(var(--foreground-divider-rgb), 6%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    border-radius: $border-radius-kanban;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background: radial-gradient(at bottom var(--shadow-position), rgba(0,0,0,0.1),transparent);
  }
}

.default-kanban-card-content {
  word-wrap: break-word;

  header {
    h5 {
      margin: 0;
      font-weight: bold;
    }

    p {
      margin: 6px 0;
      font-size: 14px;
      color: rgba(var(--foreground-divider-rgb), 60%);
    }
  }
  
  .main {
    margin-top: 12px;
    
    .info-row {
      display: flex;
      gap: 10px;
      font-size: 14px;
      color: rgba(var(--foreground-divider-rgb), 60%);
      margin-bottom: 2px;
    }
  }

  footer {
    padding-top: 12px;
    border-top: solid 1px rgba(var(--foreground-divider-rgb), 9%);
    margin-top: 12px;

    ul.tags {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin: 0;

      li {
        border: solid 1px rgba(var(--foreground-divider-rgb), 22%);
        border-radius: 40px;
        padding: 2px 10px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(var(--foreground-divider-rgb), 60%);
      }
    }
  }
}

.kanban-empty-tickets {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-weight: bold;
  opacity: 0.7;
}
