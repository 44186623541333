@use '@angular/material' as mat;
@use 'sass:color';
@import 'lib';

.conversation-view {
  .item-wrapper {
    width: 100%;
    margin-bottom: 0.5rem;

    .section-title {
      width: 100%;
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      font-weight: normal;
      display: flex;
      align-items: center;
      padding: 0.625rem;
      border-radius: $border-radius;
      background-color: rgba(var(--primary-default-rgb), 4%);
      color: var(--primary-default);
      z-index: 5;

      &[disabled] {
        cursor: initial;
      }

      &:hover {
        background-color: rgba(var(--primary-default-rgb), 8%);
      }

      >mat-icon {
        margin-right: 8px;
      }

      .right-icon-container {
        margin-left: auto;
        display: flex;
        align-items: center;

        >mat-icon {
          margin-left: 12px;
          transition: transform 0.2s ease-in-out;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }

      .title-content {
        display: flex;
        align-items: center;
        gap: .25rem;
        height: 20px;
        font-size: 1rem;
        line-height: 1;
        margin-left: 3rem;

        span.label {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 500;
        }

        app-ns-picker {
          padding: 3px;
          margin: 0;
        }
      }
    }
  }
  
  app-ns-picker.pill-mode {
    display: inline-block;
    min-width: auto;
    margin-top: 0;
    margin-bottom: 0;

    app-ns-picker-selections .content {
      margin-right: 0;
    }
  }
}

.message-item-wrapper {
  $msg-container-border-radius: 18px;
  $msg-container-border-radius-ind: 2px;

  display: flex;
  margin-bottom: 8px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .message-container {
    display: flex;
    flex-direction: column;
  }

  .message-content {
    padding: 10px 16px;
    border-radius: $msg-container-border-radius;
    color: var(--foreground-text-rgb);
    background: rgba(var(--foreground-text-rgb), 14%);
    min-height: 39px;
    max-width: 460px;
    white-space: pre-line;

    app-multimedia-instance {
      display: block;
      margin: 4px 0;
      width: 300px;
    }
  }

  .menu-title {
    font-size: $smaller-font;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 2px;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;

    >.response-to {
      padding: 2px 4px;
      border-radius: 4px;
      background: color.scale(mat.get-color-from-palette($primary, default), $lightness: -60%);
      color: var(--primary-default-contrast);
    }
  }

  .menu-title+.message-content:not(.right) {
    border-top-left-radius: $msg-container-border-radius-ind;
  }

  .message-footer {
    display: flex;
    align-items: center;
    margin: 0 0 0 4px;

    .message-date {
      font-size: 0.82rem;
      text-transform: uppercase;
      color: var(--foreground-secondary-text);
      padding: 4px;
      font-weight: bold;
    }

    .engagement-icon {
      margin-left: 4px;
    }
  }

  &.right {
    .menu-title {
      align-self: flex-end;
    }

    .menu-title+.message-content {
      border-top-left-radius: $msg-container-border-radius;
      border-top-right-radius: $msg-container-border-radius-ind;
    }

    .message-content {
      color: var(--primary-default-contrast);
      background-color: var(--primary-default);
    }

    .message-footer {
      margin: 0 4px 0 0;
      justify-content: flex-end;
    }
  }

  &.has-multimedia {
    .message-content {
      padding: 10px;
    }
  }

  .menu-option {
    border-radius: 50%;
    background: rgba(var(--foreground-text-rgb), 38%);
    font-weight: bold;
    font-size: $smaller-font;
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    margin-right: 4px;
  }

  &.is-attendant-personal {
    .message-content {
      background: color.scale(mat.get-color-from-palette($primary, default), $lightness: -60%);
      color: var(--primary-default-contrast);
    }
  }
}

.conversation-interactions-container {
  overflow: hidden;
  position: relative;
  border-radius: $border-radius;


  >.more-indicator-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    cursor: pointer;
    background: linear-gradient(to bottom, transparent, rgb(255 255 255 / 76%));

    &:hover {
      background: linear-gradient(to bottom, transparent, rgb(240 240 240 / 76%));
    }

    >.more-indicator {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: initial;
      background: rgba(var(--primary-default-rgb), 10%);
      color: var(--primary-default);
      font-size: 2rem;
      line-height: 0px;
      height: 14px;
      width: 28px;
      text-align: center;
      border-radius: $border-radius;
      z-index: 2;
      user-select: none;
      cursor: pointer;
    }
  }

  &.expanded {
    >.more-indicator-overlay {
      display: none;
    }
  }

  .message-section-container {
    $msg-container-border-radius: 18px;

    display: flex;
    flex-direction: column;

    position: relative;
    border-radius: $msg-container-border-radius;
    background-color: rgba(var(--primary-default-rgb), 6%);
    margin-bottom: 8px;
    white-space: pre-line;

    >.heading {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      padding: 10px 16px 0 16px;

      .smartfields {
        flex-basis: 30%;
      }

      .title {
        display: flex;
        align-items: center;

        mat-icon {
          margin-right: 5px;
        }
      }

      &.center .title {
        margin-left: auto;
        margin-right: auto;
      }

      .icons {
        flex-basis: 30%;
        display: flex;
        justify-content: end;

        >div {
          margin-left: 8px;
        }
      }
    }

    >.content {
      flex: 1 0 auto;
      padding: 0 16px 8px 16px;
    }

    bot-transaction-smartfield {
      flex: 0 0 auto;
      display: block;
      background-color: rgba(var(--accent-400-rgb), 10%);
      border-top: 2px solid var(--accent-400);

      &:first-of-type {
        margin-top: 10px;
      }

      &:last-child {
        border-radius: 0 0 $msg-container-border-radius $msg-container-border-radius;
      }

      >header {
        line-height: 0;

        .variable {
          border-radius: 0 0 4px 0;
          margin: 0;
        }
      }

      >.content {
        padding: 10px 16px 12px 16px;
      }
    }

    bot-transaction-action-evaluator {
      &:not(:first-child) {
        flex: 0 0 auto;
        display: block;
        background-color: rgba(var(--primary-default-rgb), 2%);
        border-top: 2px solid rgba(var(--primary-default-rgb), 15%);

        &:first-of-type {
          margin-top: 10px;
        }

        &:last-child {
          border-radius: 0 0 $msg-container-border-radius $msg-container-border-radius;
        }

        >header {
          line-height: 0;

          .variable {
            border-radius: 0 0 4px 0;
            margin: 0;
          }
        }
      }

      >.content {
        padding: 10px 16px 12px 16px;
      }
    }

    .variables-container .variable mat-icon {
      background-color: transparent;
      margin-right: 0;
    }

    .field-messages-section {
      margin-top: 15px;

      h5 {
        font-size: $smaller-font;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 6px;
      }

      bot-transaction-event {
        &:not(:last-child) .message-item-wrapper {
          margin-bottom: 3px;
        }
      }
    }
  }

  >.message-section-container {
    max-width: 40%;
  }

  ul.evaluators-list {
    margin-bottom: 8px;

    >li {
      &:not(:last-child) {
        padding-bottom: 8px;
        border-bottom: 2px solid rgba(var(--primary-default-rgb), 15%);
        margin-bottom: 8px;
      }
    }
  }
}

.engagement-icon {
  background-color: rgba(var(--primary-default-rgb), 8%);
  border-radius: 50%;
  padding: 5px;
  line-height: 0;

  &[disabled] {
    cursor: default;
  }
}

/*
  essa regra cria um pseudoelemento para impedir que o conteúdo
  do visualizador não apareça acima do header. esse bug só acontece
  quando o visualizador é aberto em um dialog
*/ /** 👏👏👏 */
.ns-visualizer-modal .mat-dialog-container::before {
  content: '';
  position: sticky;
  top: -24px;
  left: 0;
  z-index: 5;
  display: block;
  width: 100%;
  height: 25px;
  background-color: white;
  margin-bottom: -24px;
}

.his-c {
  position: absolute;
  top: 200%;
  left: 200%;
}

.historic-conv-correction {
  --size: 700px;

  width: var(--size) !important;
  .item-wrapper,
  .messages-section-title,
  .message-item-wrapper {
    width: var(--size) !important;
  }
  .page-space {
    height: 80px;
    background-color: red;
  }
}