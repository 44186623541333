@import "utilities/mixins";

.custom-var-inserter {
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-height: 250px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 4px;

  background-color: white;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);

  .search-selector-container {
    display: flex;
    align-items: center;
    gap: 4px;

    .search-selector-wrapper {
      display: flex;
      border: 1.5px solid rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      align-items: center;
      padding: 4px;

      .search-selector {
        all: unset;
        border: none;
        outline: none;
        padding-left: 6px;
        font-size: 0.875rem;
        &::placeholder {
          padding-left: 4px;
          font-style: italic;
        }
      }
    }

    .custom-var-create-button {
      display: flex;
      align-items: center;
    }
  }

  .hint {
    font-size: 0.7rem;
    margin-left: 6px;
    color: red;
  }

  .mat-list-base {
    max-height: 300px;
    overflow-y: auto;
    padding-top: 0 !important;
  }

  .line {
    margin: 0;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
  }

}
