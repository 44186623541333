app-crm-help-desk {
  display: flex;
  // padding: $app-dashboard-pd;
  // padding-left: 10px;
  // border-left: 1px solid get-color-from-pallete($colmeia-foreground, divider, 27%);

  cm-generic-dashboard-home {

    .dashboard-page {
      .header {
        .title {
          padding-left: calc($app-dashboard-pd / 2);
        }
      }
    }

    .list-container {
      .list-item {
        flex-wrap: wrap;

        .list-item-column {
          width: auto;
        }

        &.current {
          border-right: 4px solid var(--primary-default);
          // position: sticky;
          // top: 50px;
        }
      }
    }

    .top-actions,
    .bottom-actions {
      .box-style-2 {
        box-shadow: none;
        border: 1px solid get-color-from-pallete($colmeia-foreground, divider, 27%);
      }
    }

    .top-actions {
      top: -10px !important;
    }

    .bottom-actions {
      bottom: -10px !important;

      .right-actions {
        width: 100%;

        > div {
          width: 100%;
        }
      }

      cm-generic-dashboard-pagination {
        justify-content: space-evenly;

        .mat-button-toggle-label-content {
          font-size: $smaller-font;
        }

        .arrow-controls {
          margin-left: 0;
        }

        mat-icon {
          @include mat-icon-size(18px, $force: true);
        }
      }
    }
  }
}

.help-desk-page {
  #dashboard-view-content {
    padding: 0;
    overflow: auto;

    // app-empty-view {
    //   display: ;
    // }
  }
}