
.align-center {
    display: flex;
    justify-content: center;
	
	&--horizontal {
		@extend .align-center;
    	flex-direction: row;
	}

	&--vertical {
		@extend .align-center;
    	flex-direction: column;
	}
}

.vertical-line {
  height: auto;
  width: 2px;
  background-color: $light-grey;
  margin: 0 20px;
}

.scroll-overflow {
	overflow-y: scroll;
}
