app-bpm-graph-viewer {
    .container {
        .generic-home-buttons {
          > .gh-item {
            app-tag-picker {
                mat-icon {
                    color: var(--primary-A200);
                }
            }
          }
        }
    }
}
