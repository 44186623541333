@use '@angular/material' as mat;
.cdk-global-overlay-wrapper.is-dialog-window {
  transition: transform 0.4s cubic-bezier(0.63, 0.01, 0.32, 0.99), opacity  0.2s 0.2s;

  &.minimized {
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.96, -0.07, 0, 1.01), opacity 0.2s 0.2s;
    transform: translateY(100%);
  }
}

.cdk-overlay-pane {
  &.dialog-window {
    position: relative !important;

    @include mq('sm', true) {
      max-width: none !important;
      width: 100vw !important;
    }

    &.moving {
      transition: 0.4s cubic-bezier(0.19, -0.07, 0.04, 0.96);
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%; left: 50%;
      width: 100%; height: 100%;
      background:
        linear-gradient(40deg, #0000001a, transparent, #97ffdc33),
        linear-gradient(90deg, transparent, #baebd8, transparent),
        linear-gradient(142deg, #baebd478, #baebd700, transparent);
      transition: .2s ease-in-out;
      transform: translate(-50%, -50%) scale(1);
      border-radius: $border-radius;
      z-index: -1;
      opacity: 0;
      filter: blur(1px);
    }

    &:hover:before,
    &.focused:before,
    &.dragging:before,
    &.moving:before {
      opacity: 1;
    }

    &.dragging {
      &:before {
        transform: translate(-50%, -50%) scale(0.98);
      }
    }

    &.moving {
      &:before {
        transition: .4s ease-in-out;
        transform: translate(-50%, -50%) scale(1.06);
      }
    }

    &:hover:not(.moving):not(.dragging) {
      &:before {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }

    .mat-dialog-container {
      position: relative;
      z-index: 2;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      box-shadow: none;
      transition: opacity 0.6s ease-in-out;
    }

    &.focused {
      .mat-dialog-container {
        background: var(--foreground-modal-bg);
        border-color: var(--primary-default);
        opacity: 1;
        box-shadow: 0 0 0 4000px rgb(0 0 0 / 20%);
      }

    }

    &.copilot-container {
      .mat-dialog-container {
        box-shadow: unset !important;
      }
    }

    &.dragging .mat-dialog-container {
      transition: none;
      background: var(--foreground-modal-bg);
      backdrop-filter: none !important;
    }

    &.moving .mat-dialog-container {
      transition: 0.4s cubic-bezier(0.19, -0.07, 0.04, 0.96);
    }


    .dashboard-page {
      .window-content {
        background: white;
        padding: 12px;
        margin: -12px;
        border-radius: $border-radius;
        position: relative;
        height: 100%;

        &:before {
          $color: rgba(var(--primary-default-rgb), 40%);

          content: "";
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $color;
          z-index: -1;
          opacity: 0;
          transition: opacity .2s ease-in-out;
          box-shadow:
            10px 0 0 0px $color,
            -10px 0 0 0px $color
          ;
        }

        &.minified {
          &:before {
            opacity: 1;
          }
        }
      }

      mat-dialog-content {
        @extend .nice-scroll;
        padding: 2px 36px !important;
        margin: 0 -12px;
        display: block;
      }
    }

    .dashbroad-move-window-trigger {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      background: transparent;
      transform: translate(-50%, -50%);
      z-index: 99;
    }


    &.focused:not(.dragging) {
      .dashbroad-move-window-trigger {
        z-index: initial;
        cursor: move;
      }
    }
  }
}

.dialog-window-bar {
  z-index: 900;
}


.cdk-overlay-pane {
  &.dialog-window {
    &.blocked-loading {
      cursor: wait;
      filter: contrast(0.4) brightness(1.4);

      .mat-dialog-container {
        overflow: hidden;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
        }

        &:before {
          @include loadingDirectiveSpin();
          z-index: 3;
          width: 40px;
          height: 40px;
          margin-top: -20px;
          margin-left: -20px;
          border: 4px solid var(--accent-default-contrast);
          border-top-color: var(--accent-default);
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          z-index: 2;
          border-radius: 4px;
          transform: translate(-50%, -50%);
          cursor: wait;
        }
      }
    }
  }
}
