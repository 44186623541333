:root {
  // --warn-default: #f44336;
  // --warn-default-contrast: white;

  .mat-mdc-mini-fab {
      &.mat-primary {
          --mat-fab-small-foreground-color: var(--primary-default-contrast);
          --mat-fab-small-state-layer-color: var(--primary-default-contrast);
      }

      &.mat-accent {
          --mat-fab-small-foreground-color: var(--accent-default-contrast);
          --mat-fab-small-state-layer-color: var(--accent-default-contrast);
      }

      &.mat-warn {
          --mat-fab-small-foreground-color: var(--warn-default-contrast);
          --mat-fab-small-state-layer-color: var(--warn-default-contrast);
      }
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--primary-50);
  }

  .mat-mdc-fab {
    &.mat-primary {
      color: white !important;
    }
  }

  .mat-mdc-chip {
    &.mat-accent {
      background-color: var(--accent-500);
      color: var(--accent-500-contrast);
    }

    &.mat-mdc-chip-row,
    &.mat-mdc-chip-with-trailing-icon {
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 8px;
      padding-left: 12px;

      .mat-mdc-chip-trailing-icon {
        margin-left: 4px;
      }
    }
  }

  .mat-mdc-outlined-button {
    // flex-shrink: 0;
    letter-spacing: normal !important;
  }

  .mat-mdc-unelevated-button {
    letter-spacing: normal !important;
  }

  .mat-mdc-raised-button {
    letter-spacing: normal !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: rgba(var(--foreground-color-rgb),.9);
  }
}

.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 40px !important;
}
